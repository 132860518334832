import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { replaceValues } from '../utils';
import { withEdit } from '../Context';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import { Columns, Column } from '../components';
import './iframe-section.scss';
import ResizableIframe from '../components/ResizableIframe';

class IFrameSection extends PureComponent {
  renderContents() {
    const {
      source,
      iframe,
      iframePosition,
      columnsSize,
      text,
      components,
      hasAutoResize,
    } = this.props;
    const url = replaceValues(source);
    const { width, height } = iframe;
    const { RichText } = components;

    // iframe key used to force refresh iframe/reload when url or hasAutoResize changes
    const iframeEl = (
      <ResizableIframe src={url} autoResize={hasAutoResize} width={width} height={height} />
    );

    if (iframePosition === 'fullpage') return iframeEl;

    // Else left/right
    return (
      <Columns isCentered centerVertically>
        {iframePosition === 'left' && <Column className={columnsSize}>{iframeEl}</Column>}
        <Column>
          <RichText html={text} name="text" placeholder="Texte" />
        </Column>
        {iframePosition === 'right' && <Column className={columnsSize}>{iframeEl}</Column>}
      </Columns>
    );
  }

  render() {
    const { source, iframe, iframePosition, ...props } = this.props;

    return (
      <BaseSection {...props} block={this.props}>
        {!source && (
          <div style={{ padding: 32 }}>
            Paramétrez l'url du formulaire dans les options de droite
          </div>
        )}
        {this.renderContents()}
      </BaseSection>
    );
  }
}

IFrameSection.defaultProps = {
  columnsSize: 'is-half',
  source: '',
  iframePosition: 'fullpage',
  hasAutoResize: true,
  iframe: {},
  isPreview: false,
  text: '',
};

IFrameSection.propTypes = {
  columnsSize: PropTypes.string,
  hasAutoResize: PropTypes.bool,
  components: PropTypes.shape({
    RichText: PropTypes.elementType,
  }).isRequired,
  iframe: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  iframePosition: PropTypes.oneOf(['left', 'right', 'fullpage']),
  isPreview: PropTypes.bool,
  source: PropTypes.string,
  text: PropTypes.string,
};

export default withEdit(withComponents(IFrameSection));
