/* eslint-disable max-len */
/**
 * THIS FILE SHOULD BE AUTO-CONTAINED, IT'LL BE COPIED INTO appcraft-mini-service-api
 * DO NOT REQUIRE OTHER STUFF HERE PLEASE !
 */

function alphaHex(alphaLevel) {
  const str = Math.floor(alphaLevel * 255).toString(16);
  if (str.length === 1) return `0${str}`;
  return str;
}

function alpha(color, alphaLevel) {
  if (!color) return null;
  return color
    .replace(/rgb\((.*)\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/(rgba\(.*),[^,]+\)/, (_all, group1) => `rgba(${group1}, ${alphaLevel})`)
    .replace(/(#......)/, (_all, group1) => `${group1}${alphaHex(alphaLevel)}`);
}

function fontCSS(font, className) {
  if (!font) return '';
  // TODO : limit weight/etc ?

  if (font.kind === 'systemfont' && font.family !== 'Raleway') {
    return `
    ${className} {
      font-family: "${font.family}", sans-serif;
    }
    `;
  }

  const name = encodeURIComponent(font.family);
  const url = `https://fonts.googleapis.com/css?family=${name}`;
  return `
  @import url(${url});

  ${className} {
    font-family: "${font.family}", ${font.category || 'sans-serif'};
  }
  `;
}

const bodyFontRules = ['body', 'button', 'input', 'select', 'textarea', '.section', '.footer'];

function buildFontStyles({ bodyFont, menuFont, scope }) {
  if (scope) {
    const parentPrefix = scope ? `.${scope} ` : '';
    return `
    ${fontCSS(bodyFont, ['', ...bodyFontRules].map((rule) => `${parentPrefix} ${rule}`).join(', '))}
    ${fontCSS(menuFont, `${parentPrefix} .navbar`)}
`;
  }
  // TODO: test
  return `
  ${fontCSS(bodyFont, bodyFontRules.join(', '))}
  ${fontCSS(menuFont, '.navbar')}
  `;
}

function buildCSS({ primaryColor = '#1678c2', scope }) {
  const parentPrefix = scope ? `.${scope} ` : '';

  return `
  
/* Color styles */
${parentPrefix} .navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.0);
  transition: border-bottom-color 250ms ease-in-out;
  background-color: ${primaryColor};
}

${parentPrefix} .navbar.secondary a.navbar-item > span  {
  background-color: ${primaryColor};
}

${parentPrefix} .container--left-menu .menu .menu-list a.is-active {
  background-color: ${primaryColor};
  color: #fff;
}

${parentPrefix} .navbar.invert .navbar-item > span {
  color: ${primaryColor};
}

${parentPrefix} .navbar.invert .navbar-item.is-active {
  background-color: rgba(0, 0, 0, 0.05);
}

${parentPrefix} .is-topLogo-menu .logo--container {
  background-color: ${primaryColor};
}



${parentPrefix} .c-searchbar__label {
  color: ${primaryColor};
}

${parentPrefix} .c-searchbar span.icon.is-left {
  background-color: ${primaryColor};
}

${parentPrefix} .c-searchbar .control.has-icons-left > input, 
${parentPrefix} .c-searchbar .dropdown-trigger > .button {
  border: 1px solid ${primaryColor};
}

${parentPrefix} .c-searchbar .dropdown span.icon {
  color: ${primaryColor};
}

${parentPrefix} .item--link .button {
  background-color: ${primaryColor};
  color: white;
}

${parentPrefix} .list-section__icon {
  color: ${primaryColor};
}

${parentPrefix} .list-section__social {
  color: ${primaryColor}!important;
}

${parentPrefix} .image.image--primary-shadow {
  display: block;
  position: relative;
  box-shadow: -32px 32px ${primaryColor};
  margin-bottom: 32px;
}

${parentPrefix} .image.image--right.image--primary-shadow {
  box-shadow: 32px 32px ${primaryColor};
}

${parentPrefix} footer.footer {
  background-color: ${primaryColor};
  padding: 10px;
}

${parentPrefix} .c-arrow--scroll-to-top {
  background-color: ${primaryColor};
}

${parentPrefix} form .button {
  background-color: ${primaryColor};
}
${parentPrefix} .c-icon {
  color: ${primaryColor};
}
${parentPrefix} .c-icon--square, ${parentPrefix} .c-icon--circle, ${parentPrefix} .c-icon--hexa {
  color: #fff;
  background: ${primaryColor};
}
${parentPrefix} .c-icon--bubble {
  border-color: ${primaryColor};
  color: ${primaryColor};
}
${parentPrefix} .c-icon--hexa:before {
  border-bottom-color: ${primaryColor};
}
${parentPrefix} .c-icon--hexa:after {
  border-top-color: ${primaryColor};
}
${parentPrefix} .c-icon-hover:hover .c-icon--diamond:before,
${parentPrefix} .c-icon--diamond:hover:before {
  border-color: ${primaryColor};
}
${parentPrefix} .c-item-list__item  .c-item-list__hexa {
    background: ${primaryColor};
}

${parentPrefix} .c-item-list__item .c-item-list__hexa:before {
  border-bottom-color: ${primaryColor};
}
${parentPrefix} .c-item-list__item .c-item-list__hexa:after {
  border-top-color: ${primaryColor};
}
${parentPrefix} .c-item-list__item .title {
  background: ${primaryColor};
}

${parentPrefix} .c-item--partner--card:hover {
  border-color: ${primaryColor};
}

${parentPrefix} .c-item--partner--card .title {
  background-color: ${primaryColor};
  color: #fff;
}

${parentPrefix} .c-opportunity .border, 
${parentPrefix} .c-opportunity .border {
  background-color: ${primaryColor};
}

${parentPrefix} .c-opportunity.simple .fields-container {
  background-color: ${primaryColor};
}

${parentPrefix} .c-opportunity:hover {
  box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px ${primaryColor}!important;
}

${parentPrefix} .c-opportunity div>i, 
${parentPrefix} .c-opportunity__group-label {
  color: ${primaryColor};
}

${parentPrefix} .c-opportunity-details hr, 
${parentPrefix} .c-opportunity-details .left-border {
  background-color: ${primaryColor};
}

${parentPrefix} .c-opportunity-details a.back-button { 
  color: ${primaryColor};
}

${parentPrefix} .c-opportunity-details h2.section-title {
  margin-bottom: 10px;
}

${parentPrefix} .c-opportunity-details h2.section-title > i {
  color: ${primaryColor};
  text-align: left; 
  width: auto; 
  margin-right: 10px;
}

${parentPrefix} .tabs li.is-active a {
  border-bottom-color: ${primaryColor};
  color: ${primaryColor};
}

${parentPrefix} .c-program-table__item__time {
  color: ${primaryColor};
}

${parentPrefix} .vertical--timeline:before, 
${parentPrefix} .vertical--timeline__item--icon, 
${parentPrefix} .vertical--timeline__item--content h2.mainside__title  {
  background: ${primaryColor};
}

${parentPrefix} .vertical--timeline__item--content .mainside__button {
  color: ${primaryColor};
  border-color: ${primaryColor};
  box-shadow: 2px 2px 0 ${primaryColor};
}

${parentPrefix} .vertical--timeline__item--content .mainside>.mainside__arrow--left  {
  border-left: 7px solid ${primaryColor};
}

${parentPrefix} .vertical--timeline__item--content .mainside>.mainside__arrow--right {
  border-right-color: ${primaryColor};
}

${parentPrefix} .image--color-overlay::after {
  background: ${alpha(primaryColor, 0.35)};
}

${parentPrefix} .hex p {
  background-color: ${alpha(primaryColor, 0.7)};
}

@media screen and (max-width: 480px) {
  ${parentPrefix} .vertical--timeline__item--content .mainside:before {
    border-right-color: ${primaryColor};  
  }
}

${parentPrefix} .card--metro .title {
  color: ${primaryColor};
}


${parentPrefix} ${parentPrefix} .timeline-event:hover .timeline-event-icon {
  background-color:${primaryColor};
}

${parentPrefix} .timeline-event:hover .timeline-event-header {
  background-color: ${primaryColor};
}

${parentPrefix} .workshop--filters .dropdown i, 
${parentPrefix} .workshops .workshop .main.title, 
${parentPrefix} .workshops .workshop .date  {
  color: ${primaryColor};
}

${parentPrefix} .workshops .workshop .category {
  background-color: ${primaryColor};
}

${parentPrefix} .workshop--content .header {
  border-bottom-color: ${primaryColor};
}

${parentPrefix} .workshops .workshop .footer, .workshops.list .left-section {
  border-top-color: ${primaryColor};
}

${parentPrefix} .workshop .tag.register {
  backgroundColor: alpha(${primaryColor}, 0.1);
  color: ${primaryColor};
  border: 1px solid ${primaryColor};
}

${parentPrefix} .mobile-footer-button .button{
  background-color: ${primaryColor};
  color: #fff;
}

${parentPrefix} .countdown .clock {
  background-color: ${primaryColor};
  color: #fff;
}

${parentPrefix} .section--dark .countdown .clock {
  background-color: #fff;
  color: ${primaryColor};
}

${parentPrefix} .countdown-item{  
  color: ${primaryColor};  
}

${parentPrefix} .section--dark .countdown-item  {  
  color: #fff;  
}

${parentPrefix} .countdown-svg path {
  stroke: ${primaryColor};
}

${parentPrefix} .section--dark .countdown-svg path {
  stroke: #fff;
}

${parentPrefix} .navbar .navbar-menu a.navbar-item--tab {
  border-bottom-color: ${primaryColor};
}

${parentPrefix} .navbar.invert .navbar-menu a.navbar-item--tab:hover, .navbar.invert .navbar-menu a.navbar-item--tab.is-active {
  border-bottom-color: ${primaryColor};
}

${parentPrefix} .navbar.invert .navbar-menu a.navbar-item--button {
  background-color: ${primaryColor};
}

${parentPrefix} .navbar .navbar-menu a.navbar-item--button span {
  color: ${primaryColor};
}
`;
}

module.exports = {
  alpha,
  buildCSS,
  buildFontStyles,
};
