import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withComponents } from './Context';
import { withEdit, EditContext } from '../Context';
import { NavLinkWithParams } from '.';
import { noop } from '../utils';
import { MenuItem } from './Menu';
import HeaderMenu from './HeaderMenu';
import { languageProps } from '../utils/propTypes';

class MenuTopLogo extends PureComponent {
  state = {
    isActive: false,
    showLogo: true,
    logoHeight: 0,
  };

  offset = 0;

  logoHeight = 0;

  componentDidMount() {
    this.getWindow().addEventListener('scroll', this.handleScroll);
    this.refreshOffset();
  }

  componentWillUnmount() {
    this.getWindow().removeEventListener('scroll', this.handleScroll);
  }

  getWindow = () => {
    // eslint-disable-next-line no-restricted-globals
    const iframe = window.editorIframe;
    if (iframe) return iframe.contentWindow;
    // eslint-disable-next-line no-restricted-globals
    return window;
  };

  handleToggleBurgerMenu = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  closeBurgerMenu = () => {
    this.setState({ isActive: false });
  };

  handleScroll = () => {
    if (new Date().getTime() > this.lastRefreshTime + 1000) {
      // Refresh offset once a second (in case images load, page size changes, etc...)
      this.refreshOffset();
    }
    const showLogoWindow = this.getWindow().pageYOffset <= this.offset;
    const { showLogo } = this.state;
    if (showLogoWindow !== showLogo) {
      this.setState({ showLogo: showLogoWindow });
    }
  };

  handleChange = (key, value) => {
    const { onChange } = this.props;
    if (key === 'logo') {
      onChange('logo', key, value ? value.uri : '');
    }
  };

  refreshOffset() {
    const { menu = {} } = this.props;
    const { snapLogo } = menu;
    const rect = this.logoContainer.getBoundingClientRect();
    this.logoHeight = rect.bottom - rect.top; // + window.document.documentElement.scrollTop;
    if (snapLogo) {
      this.offset = 0; // Snap from the beginning...
    } else {
      this.offset = this.logoHeight;
    }
    this.lastRefreshTime = new Date().getTime();
    this.setState({ logoHeight: this.logoHeight });
  }

  render() {
    const {
      logo,
      pages,
      components,
      isEditing,
      menu = {},
      languages,
      language,
      onLanguageChange,
    } = this.props;
    const {
      horizontalAlign = 'center',
      invertColors,
      snapLogo = false,
      showHeaderMenu = false,
      headerMenuSettings = {},
    } = menu;
    const { isActive, showLogo, logoHeight } = this.state;
    const { Image } = components;
    const active = isActive ? 'is-active' : '';
    const secondaryPages = pages.filter((p) => p.type === 'secondary');
    return (
      <EditContext.Provider value={{ onChange: this.handleChange }}>
        <div className="is-topLogo-menu">
          <div
            ref={(ref) => {
              this.logoContainer = ref;
            }}
            className={cx('logo--container', {
              'sticky-menu': !showLogo && snapLogo,
            })}
          >
            <NavLinkWithParams to="/" disabled={isEditing}>
              <Image src={logo} name="logo" alt="Logo" isUnsplash={false} />
            </NavLinkWithParams>
          </div>
          <nav
            id="navbar"
            style={{
              height: 60,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 0,
              margin: 0,
              top: !showLogo && snapLogo ? logoHeight : undefined,
            }}
            className={cx('navbar', {
              'sticky-menu': !showLogo,
              invert: invertColors,
            })}
          >
            <div className="container">
              <div className={cx('navbar-menu', active, `h-${horizontalAlign}`)}>
                {pages
                  .filter(
                    (p) => p.type !== 'subpage' && p.type !== 'secondary' && p.type !== 'hidden',
                  ) // show only main pages
                  .map((item) => (
                    <MenuItem
                      key={item._id}
                      item={item}
                      menu={menu}
                      onCloseBurgerMenu={this.closeBurgerMenu}
                    />
                  ))}
              </div>
            </div>
            {showHeaderMenu && (
              <div style={{ position: 'absolute', top: 10, right: 20 }}>
                <HeaderMenu
                  language={language}
                  languages={languages}
                  invertColors={invertColors}
                  settings={headerMenuSettings}
                  onLanguageChange={onLanguageChange}
                />
              </div>
            )}
          </nav>
          {secondaryPages.length > 0 && (
            <nav
              id="navbar-secondary"
              className={cx('navbar', 'secondary', {
                'sticky-menu': !showLogo || snapLogo,
                invert: invertColors,
              })}
            >
              <div className="container">
                <div className={cx('navbar-menu', active, `h-${horizontalAlign}`)}>
                  {secondaryPages.map((item) => (
                    <MenuItem
                      key={item._id}
                      item={item}
                      menu={menu}
                      onCloseBurgerMenu={this.closeBurgerMenu}
                    />
                  ))}
                </div>
              </div>
            </nav>
          )}
          {!showLogo && (
            <React.Fragment>
              {/* Add logo duplicate to kep the correct size */}
              {snapLogo && (
                <div className={cx('logo--container')} style={{ opacity: 0 }}>
                  <a href="#" onClick={noop}>
                    <Image src={logo} name="logo" alt="Logo" isUnsplash={false} />
                  </a>
                </div>
              )}
              <div style={{ height: 60 }} />
            </React.Fragment>
          )}
        </div>
      </EditContext.Provider>
    );
  }
}

MenuTopLogo.defaultProps = {
  isEditing: false,
  language: undefined,
  languages: [],
  logo: '',
  menu: {},
  pages: [],
};

MenuTopLogo.propTypes = {
  isEditing: PropTypes.bool,
  components: PropTypes.object.isRequired,
  language: PropTypes.string,
  languages: PropTypes.arrayOf(languageProps),
  logo: PropTypes.string,
  menu: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  pages: PropTypes.array,
};

export default withEdit(withRouter(withComponents(MenuTopLogo)));
