import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';

const Field = ({
  type,
  disabled = false,
  fieldType,
  name,
  onChange,
  onSubmit,
  placeholder,
  required,
  state,
  validate,
  value = '',
}) => {
  const extraClassName = validate && required && !value ? 'is-danger' : '';
  if (type === 'input')
    return (
      <input
        className={`input ${extraClassName}`}
        type={fieldType}
        required={required}
        name={name}
        placeholder={placeholder}
        onChange={(e) => onChange(name, e.target.value)}
        value={value}
        disabled={disabled}
      />
    );
  if (type === 'textarea')
    return (
      <textarea
        className={`textarea ${extraClassName}`}
        required={required}
        name={name}
        placeholder={placeholder}
        onChange={(e) => onChange(name, e.target.value)}
        value={value}
      />
    );
  if (type === 'button')
    return (
      <button
        type="submit"
        onClick={onSubmit}
        disabled={state === 'sending'}
        className={cx('button', { 'is-loading': state === 'SENDING' })}
        style={{ fontWeight: 'bold', color: 'white' }}
      >
        {name}
      </button>
    );
  return undefined;
};

export default class FormField extends PureComponent {
  renderFormField = (type) => {
    const {
      fieldType,
      validate,
      required,
      name,
      placeholder,
      onChange,
      value = '',
      disabled = false,
      onSubmit,
      state,
    } = this.props;
    const extraClassName = validate && required && !value ? 'is-danger' : '';
    if (type === 'input') {
      return (
        <input
          className={`input ${extraClassName}`}
          type={fieldType}
          required={required}
          name={name}
          placeholder={placeholder}
          onChange={(e) => onChange(name, e.target.value)}
          value={value}
          disabled={disabled}
        />
      );
    }
    if (type === 'textarea') {
      return (
        <textarea
          className={`textarea ${extraClassName}`}
          required={required}
          name={name}
          placeholder={placeholder}
          onChange={(e) => onChange(name, e.target.value)}
          value={value}
        />
      );
    }
    if (type === 'button') {
      return (
        <button
          type="submit"
          onClick={onSubmit}
          disabled={state === 'sending'}
          className={cx('button', { 'is-loading': state === 'SENDING' })}
          style={{ fontWeight: 'bold', color: 'white' }}
        >
          {name}
        </button>
      );
    }
    return null;
  };

  render() {
    const { icon, required, validate, value, requiredLabel } = this.props;
    const hasIconLeft = icon ? 'has-icons-left' : '';
    return (
      <div className="field">
        <p className={cx('control', hasIconLeft)}>
          <Field {...this.props} />
          {icon && (
            <span className="icon is-small is-left">
              <i className={`fas fa-${icon}`} />
            </span>
          )}
        </p>
        {validate && required && !value && <p className="help is-danger">{requiredLabel}</p>}
      </div>
    );
  }
}

Field.defaultProps = {
  disabled: false,
  fieldType: '',
  name: '',
  onChange: noop,
  onSubmit: noop,
  placeholder: '',
  required: false,
  state: '',
  validate: false,
  value: '',
};

Field.propTypes = {
  disabled: PropTypes.bool,
  fieldType: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  state: PropTypes.string,
  validate: PropTypes.bool,
  value: PropTypes.string,
};

FormField.defaultProps = {
  icon: undefined,
  required: false,
  requiredLabel: false,
  validate: false,
  value: undefined,
};

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  required: PropTypes.bool,
  requiredLabel: PropTypes.string,
  validate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};
