import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import get from 'lodash/get';
import Handlebars from 'handlebars';
import { withRouter } from 'react-router-dom';
import { getParameterByName } from '../utils';
import { withAppContext } from '../Context';
import { appDataProps } from '../utils/propTypes';

Handlebars.registerHelper('get', (path, opts) => {
  return get(opts, `data.root.${path}`);
});

Handlebars.registerHelper('ifEquals', (arg1, arg2, options) => {
  // eslint-disable-next-line eqeqeq
  return arg1 == arg2 ? options.fn(this) : options.inverse(this);
});
Handlebars.registerHelper('ifNotEquals', (arg1, arg2, options) => {
  // eslint-disable-next-line eqeqeq
  return arg1 != arg2 ? options.fn(this) : options.inverse(this);
});

function htmlEntities(str) {
  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
}

function replaceValues(baseText, data) {
  let text = baseText;
  const { user } = data;
  if (text.indexOf('{{') !== -1) {
    // Use handlebars
    try {
      const template = Handlebars.compile(text.replace(/&quot;/g, '"')); // Force quotes
      // TODO: FIX DATA
      text = template(user);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse handlebars template', e);
    }
  }
  return text.replace(/{(.*?)}/g, (match, key) => {
    if (key.indexOf('user.') === 0) {
      if (!user) return '';

      const userKey = key.replace('user.', '');
      return htmlEntities(get(user, userKey) || '');
    }

    const value = getParameterByName(key) || (user && user[key.replace('user.', '')]);
    return value ? htmlEntities(value) : '';
  });
}

class RichText extends PureComponent {
  componentDidMount() {
    this.checkLinks();
  }

  componentDidUpdate() {
    this.checkLinks();
  }

  registerRef = (el) => {
    this.htmlElement = el;
  };

  /**
   * Intercept <a> click event, convert into history push if appropriate (ie internal link)
   */
  handleLink = (e) => {
    const { data } = this.props;
    const { disableRouter } = data;
    // SSR TODO: if the router is disabled, enhance link to inject location.search ?
    if (disableRouter) return; // Let the browser do it !

    // Only handle click on a tags
    if (!e.target.href) {
      // Not a valid link...
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    let url = e.target.href;
    // eslint-disable-next-line no-restricted-globals
    const { location } = window;
    if (!e.target.search && location.search) {
      // Inject search !
      url += `${location.search}`;
    }
    const { history } = this.props;
    if (history) {
      const baseUrl = `${location.protocol}//${location.host}/${data.basename}`;
      const targetPath = url.replace(baseUrl, '');
      history.push(targetPath);
    }
  };

  /**
   * HTML blocks can contain inner links to other pages
   * This code searches for links inside the HTML blob, checks if they point
   * to other pages of the same site, and patches them to call a click handler
   * instead of the normal browser click.
   * The click handler then pushes the url change to react-router's history
   */
  checkLinks = () => {
    if (!this.htmlElement) return;
    const { data } = this.props;
    const links = this.htmlElement.getElementsByTagName('a');
    const { basename = '' } = data;
    // TODO: inject language ?
    // eslint-disable-next-line no-restricted-globals
    const { location } = window;
    const currentPath = `${location.protocol}//${location.host}/${basename}`;
    for (const link of links) {
      // Only replace if same domain...
      if (link.href.indexOf(currentPath) !== -1) {
        // TODO: add check that we know this page...
        // Found link !!
        link.onclick = this.handleLink;
      }
    }
  };

  render() {
    const { html, className, style, data } = this.props;
    if (!html) return null;
    return (
      <div
        className={`c-richtext content ${className}`}
        ref={this.registerRef}
        style={style}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: replaceValues(html, data) }}
      />
    );
  }
}

RichText.defaultProps = {
  className: '',
  data: {},
  history: undefined,
  html: '',
  style: {},
};

RichText.propTypes = {
  className: PropTypes.string,
  data: appDataProps,
  history: PropTypes.object,
  html: PropTypes.string,
  style: PropTypes.object,
};

export default withAppContext(withRouter(RichText));
