import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FAIcon } from '../../components';
import { formatDate, formatDatetime, languages } from '../utils';
import { splitLines } from '../../bulma/utils';
import { Figure } from '../../bulma';
import { AppContext } from '../../Context';
import { replaceDataValues } from '../../utils';

export const WorkshopCTA = ({ workshop, text, url }) => {
  if (!text || !url || !workshop) return null;
  return (
    <div style={{ marginTop: -16, marginBottom: 8 }}>
      <a
        className="button is-link"
        href={replaceDataValues(url, workshop)}
        target="_blank"
        rel="noreferrer"
      >
        {replaceDataValues(text, workshop)}
      </a>
    </div>
  );
};

WorkshopCTA.defaultProps = {
  text: '',
  url: '',
};

WorkshopCTA.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  workshop: PropTypes.object.isRequired,
};

export const WorkshopTags = ({ tagList }) => {
  if (!tagList || (tagList && tagList.length === 0)) return null;
  return (
    <div>
      {tagList.map((tag) => (
        <span key={tag} className="tag">
          {tag}
        </span>
      ))}
    </div>
  );
};

export const WorkshopTitle = ({ title }) => {
  if (!title) return null;
  return (
    <h4 className="title main is-4" html={title} style={{ marginBottom: 8 }}>
      {splitLines(title)}
    </h4>
  );
};

export const WorkshopDate = ({ startDate, endDate, format }) => {
  const { lang = 'fr' } = useContext(AppContext);
  if (!startDate) return null;
  return (
    <>
      <div className="date">
        <FAIcon icon="calendar" /> {formatDate(startDate, { lang })}
      </div>
      <div className="date">
        <FAIcon icon="clock" /> {formatDatetime(startDate, format, { lang })}
        {endDate ? ` - ${formatDatetime(endDate, format, { lang })}` : null} GMT+1
      </div>
    </>
  );
};

export const WorkshopDescription = ({ description }) => {
  if (!description) return null;
  if (description.indexOf('</') !== -1) {
    // Found HTML...
    // eslint-disable-next-line react/no-danger
    return <div className="description" dangerouslySetInnerHTML={{ __html: description }} />;
  }
  return <div className="description">{description}</div>;
};

export const WorkshopCategory = ({ category }) => {
  if (!category) return null;
  return <div className="category">{category}</div>;
};

export const WorkshopLang = ({ lang }) => {
  if (!lang) return null;
  return <Figure className="lang" format="is-24x24" src={languages[lang]} title="flag" />;
};

export const WorkshopUserCount = ({ showUserCount, userCountLabel, userCount }) => {
  if (!showUserCount || !userCount) return null;
  return (
    <span className="tag userCount">
      <FAIcon icon="users" />
      {userCount} {userCountLabel}
    </span>
  );
};

export const WorkshopDetailsButton = ({ detailsLabel, showDetails, onClick }) => {
  if (!showDetails) return null;
  return (
    <span className="tag is-medium register" onClick={onClick}>
      {detailsLabel}
    </span>
  );
};

WorkshopTags.defaultProps = {
  tagList: undefined,
};
WorkshopTags.propTypes = {
  tagList: PropTypes.arrayOf(PropTypes.string),
};

WorkshopTitle.defaultProps = {
  title: undefined,
};
WorkshopTitle.propTypes = {
  title: PropTypes.string,
};

WorkshopDescription.defaultProps = {
  description: undefined,
};
WorkshopDescription.propTypes = {
  description: PropTypes.string,
};

WorkshopLang.defaultProps = {
  lang: undefined,
};
WorkshopLang.propTypes = {
  lang: PropTypes.string,
};

WorkshopCategory.defaultProps = {
  category: undefined,
};
WorkshopCategory.propTypes = {
  category: PropTypes.string,
};

WorkshopDate.defaultProps = {
  endDate: undefined,
  format: 'HH:mm',
};
WorkshopDate.propTypes = {
  endDate: PropTypes.string,
  format: PropTypes.string,
  startDate: PropTypes.string.isRequired,
};

WorkshopDetailsButton.defaultProps = {
  detailsLabel: '',
  showDetails: false,
};
WorkshopDetailsButton.propTypes = {
  detailsLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showDetails: PropTypes.bool,
};

WorkshopUserCount.defaultProps = {
  showUserCount: false,
  userCountLabel: '',
};
WorkshopUserCount.propTypes = {
  showUserCount: PropTypes.bool,
  userCount: PropTypes.number.isRequired,
  userCountLabel: PropTypes.string,
};
