import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import padStart from 'lodash/padStart';

const itemList = [
  { label: 'jours', key: 'days' },
  { label: 'heures', key: 'hours' },
  { label: 'minutes', key: 'minutes' },
  { label: 'secondes', key: 'seconds' },
];

const Countdown = React.memo(({ background: backgroundColor, color, labels, timeLeft, type }) => {
  return (
    <div className="countdown">
      {itemList.map((item) => {
        const { label, key } = item;
        return (
          <div key={key} className={cx('clock', type)} style={{ backgroundColor, color }}>
            <div className="clock-counter">{padStart(timeLeft[key], 2, '0')}</div>
            <div className="clock-label">{labels[key] || label}</div>
          </div>
        );
      })}
    </div>
  );
});

Countdown.defaultProps = {
  background: undefined,
  color: undefined,
  labels: {},
};
Countdown.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  labels: PropTypes.shape({
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string,
  }),
  timeLeft: PropTypes.shape({
    days: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(['square', 'circle1']).isRequired,
};

export default Countdown;
