import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { Columns, Column, ProgramItemList, ProgramItemTable } from '../components';
import { withComponents } from '../components/Context';
import { iconProps } from '../utils/propTypes';

const defaultItem = {
  items: [{ title: '9h - 18h', description: 'Description', icon: 'coffee' }],
};

class ProgramDay extends PureComponent {
  render() {
    const { title, align, components, template, ...item } = this.props;
    const { RichText } = components;
    return (
      <div>
        <div style={{ fontSize: 20, fontWeight: 'bold' }}>
          <RichText html={title} name="title" placeholder="Add title" className={align} />
        </div>
        {template === 'table' ? (
          <ProgramItemTable {...item} align={align} />
        ) : (
          <ProgramItemList {...item} align={align} />
        )}
      </div>
    );
  }
}

ProgramDay.defaultProps = {
  template: 'icons',
  title: '',
};

ProgramDay.propTypes = {
  align: PropTypes.oneOf(['left', 'right']).isRequired,
  components: PropTypes.object.isRequired,
  template: PropTypes.oneOf(['icons', 'table']),
  title: PropTypes.string,
};

class ProgramSection extends PureComponent {
  renderItem = (item, index) => {
    const { components, imagePosition, template } = this.props;
    return (
      <ProgramDay
        key={item._id || index}
        {...item}
        template={template}
        components={components}
        align={imagePosition === 'right' ? 'right' : 'left'}
      />
    );
  };

  render() {
    const {
      image,
      template,
      imagePosition,
      items,
      components,
      gridSize: size,
      ...props
    } = this.props;
    const { Grid, Image } = components;
    const gridSize = imagePosition === 'none' || imagePosition === 'top' ? size : 1;
    return (
      <BaseSection {...props} block={this.props}>
        {imagePosition === 'top' && (
          <div className="image-wrapper--top">
            <Image src={image} maxWidth={1200} />
          </div>
        )}
        <Columns centerVertically>
          {imagePosition === 'left' && (
            <Column className="is-half">
              <Image src={image} maxWidth={700} />
            </Column>
          )}
          <Column className="force-flex-grow">
            <Grid
              name="items"
              items={items}
              gridSize={gridSize}
              render={this.renderItem}
              addLabel="Add day"
              defaultItem={defaultItem}
            />
          </Column>
          {imagePosition === 'right' && (
            <Column className="is-half">
              <Image src={image} maxWidth={700} />
            </Column>
          )}
        </Columns>
      </BaseSection>
    );
  }
}

ProgramSection.defaultProps = {
  gridSize: 2,
  image: undefined,
  imagePosition: 'left',
  items: [],
  template: 'icons',
};

ProgramSection.propTypes = {
  components: PropTypes.object.isRequired,
  gridSize: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imagePosition: PropTypes.oneOf(['none', 'left', 'right', 'top']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          icon: iconProps,
          description: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ),
  template: PropTypes.oneOf(['icons', 'table']),
};

export default withComponents(ProgramSection);
