import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { replaceDataValues } from '../utils';
import { Card, Media } from '../bulma';
import { imageProps as bulmaImageProps } from '../bulma/propTypes';
import './metro-item.scss';
import Images from '../utils/Images';

export class MetroItem extends React.PureComponent {
  render() {
    const {
      className,
      item,
      textAlign,
      imageField,
      imageProps,
      titleTemplate,
      subtitleTemplate,
      descriptionTemplate,
      linkField,
      linkLabelTemplate,
    } = this.props;
    const title = replaceDataValues(titleTemplate, item).trim();
    const subtitle = replaceDataValues(subtitleTemplate, item).trim();
    const description = replaceDataValues(descriptionTemplate, item).trim();
    const image = imageField && item[imageField];
    const link = linkField && item[linkField];
    const linkLabel = linkLabelTemplate && replaceDataValues(linkLabelTemplate, item).trim();
    return (
      <Card className={cx('card--metro', className)} style={{ marginBottom: 0 }}>
        {Images.exists(image) && (
          <Card.Image
            {...Images.srcSet.maxWidth(image, 500, { type: 'auto' })}
            alt={title}
            loading="lazy"
            {...imageProps}
          />
        )}
        <Card.Content style={{ textAlign }}>
          <Media title={title} subtitle={subtitle} textStyle={{ textAlign }} />
          {description && <div className="content">{description}</div>}
        </Card.Content>
        {link && (
          <Card.Content>
            <a href={link} target="_blank">
              {linkLabel || link}
            </a>
          </Card.Content>
        )}
      </Card>
    );
  }
}

export const MetroInsideItem = (props) => <MetroItem className="card--content-inside" {...props} />;

MetroItem.defaultProps = {
  textAlign: 'center',
  className: undefined,
  imageField: undefined,
  imageProps: undefined,
  titleTemplate: '',
  subtitleTemplate: '',
  descriptionTemplate: '',
  linkField: '',
};

MetroItem.propTypes = {
  className: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  item: PropTypes.object.isRequired,
  imageField: PropTypes.string,
  imageProps: bulmaImageProps,
  titleTemplate: PropTypes.string,
  subtitleTemplate: PropTypes.string,
  descriptionTemplate: PropTypes.string,
  linkField: PropTypes.string,
};
