import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import { withComponents } from './Context';
import { withEdit } from '../Context';

class Partner extends PureComponent {
  handleChange = (e) => {
    const { namePrefix, onChange } = this.props;
    const { name, value } = e.target;
    if (namePrefix) {
      onChange(`${namePrefix}.${name}`, value);
    } else {
      onChange(name, value);
    }
  };

  render() {
    const { title, template, image, url, components, isEditing } = this.props;
    const { Image, RichText } = components;

    return (
      <div className={`c-item--partner ${template ? `c-item--partner--${template}` : ''}`}>
        {!isEditing && url ? (
          <a href={url} target="_blank" rel="noreferrer">
            <Image src={image} />
          </a>
        ) : (
          <Image src={image} />
        )}
        <RichText
          className="title"
          name="title"
          html={title}
          placeholder="Add title"
          style={{ textAlign: 'center' }}
        />
        {isEditing && (
          <input
            type="text"
            value={url}
            name="url"
            placeholder="URL"
            onChange={this.handleChange}
            style={{ width: '100%' }}
          />
        )}
      </div>
    );
  }
}

Partner.defaultProps = {
  isEditing: false,
  image: '',
  namePrefix: '',
  onChange: noop,
  template: '',
  title: '',
  url: '',
};

Partner.propTypes = {
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  image: PropTypes.string,
  namePrefix: PropTypes.string,
  onChange: PropTypes.func,
  template: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

const PartnerItem = withEdit(withComponents(Partner));

class Partners extends PureComponent {
  renderItem = (item) => {
    const { isEditing, template } = this.props;
    return <PartnerItem template={template} {...item} isEditing={isEditing} />;
  };

  render() {
    const { partners, gridSize, components } = this.props;
    const { Grid } = components;
    return (
      <Grid
        items={partners}
        name="itemList.items"
        gridSize={gridSize}
        centerVertically
        isCentered
        tabletGridSize={Math.floor(gridSize / 2)}
        render={this.renderItem}
      />
    );
  }
}

Partners.defaultProps = {
  gridSize: 4,
  isEditing: false,
  partners: [],
  template: '',
};

Partners.propTypes = {
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  gridSize: PropTypes.number,
  partners: PropTypes.array,
  template: PropTypes.string,
};

export default withComponents(Partners);
