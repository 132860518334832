import React, { PureComponent, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withConfigs, withEdit, withDesign, EditContext } from '../Context';
import { ComponentContext } from '../components/Context';
import Divider from '../dividers';
import Images from '../utils/Images';
import { extractColorLuma } from '../utils/colors';

const styles = {
  bottom: { position: 'absolute', left: 0, bottom: 0, right: 0, width: 'inherit' },
};

/**
 * RichTextHeader optionnaly injects a header if required (ie non specified in html block)
 * If not editing and if html is empty, it also completely removes the node
 */
const RichTextHeader = ({ as: Header, html, isEditing, ...props }) => {
  // Nothing to show ?
  if (!isEditing && !html) return null;

  // Check if html contains a header or not
  if (html.indexOf('<h') !== -1) {
    // Found header, don't output this one as header
    return <div {...props} />;
  }
  return <Header {...props} />;
};

RichTextHeader.defaultProps = {
  html: '',
  isEditing: false,
};

RichTextHeader.propTypes = {
  as: PropTypes.string.isRequired,
  html: PropTypes.string,
  isEditing: PropTypes.bool,
};

const SectionHeader = ({ title, subtitle, defaultStyle, isEditing }) => {
  const { RichText } = useContext(ComponentContext);
  return (
    <React.Fragment>
      <RichTextHeader
        as="h2"
        className="title"
        style={defaultStyle}
        html={title}
        isEditing={isEditing}
      >
        <RichText html={title} name="title" placeholder="Add title" />
      </RichTextHeader>
      <RichTextHeader
        as="h3"
        className="subtitle"
        style={defaultStyle}
        html={subtitle}
        isEditing={isEditing}
      >
        <RichText html={subtitle} name="subtitle" placeholder="Add subtitle" />
      </RichTextHeader>
    </React.Fragment>
  );
};

SectionHeader.defaultProps = {
  defaultStyle: undefined,
  title: '',
  subtitle: '',
  isEditing: false,
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  defaultStyle: PropTypes.object,
  isEditing: PropTypes.bool,
};

function isBackgroundInsteadOfColor(bgColor) {
  return bgColor && bgColor.indexOf('gradient') !== -1;
}

class BaseSection extends PureComponent {
  handleChange = (key, value) => {
    const { _id, onChange } = this.props;
    onChange(_id, key, value);
  };

  getBackgroundUri = (backgroundImage) => {
    if (!backgroundImage) return backgroundImage;
    if (typeof backgroundImage === 'string') return backgroundImage;
    return backgroundImage.uri;
  };

  getBackground = () => {
    // TODO: use styles on section id (or StyledComponents) and media queries ?
    const { backgroundImage, backgroundImageMaxWidth = 1600 } = this.props;
    if (!backgroundImage) return null;
    return {
      backgroundImage:
        backgroundImage && `url(${Images.maxWidth(backgroundImage, backgroundImageMaxWidth)})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
  };

  resolveColor = (color) => {
    const { design } = this.props;
    return color === '@primaryColor' ? design.primaryColor : color;
  };

  render() {
    const {
      hasHeader,
      className,
      _id,
      isEditing,
      divider,
      type,
      name,
      configs,
      backgroundColor,
      nextColor,
      language,
      languages,
      opacity,
      children,
      title,
      subtitle,
      block,
      containerStyle,
      isPreview,
      isFullWidth,
      StyleDropdown,
    } = this.props;
    const editConfig = configs[type];
    const bgColor = this.resolveColor(backgroundColor);
    const shapeColor = this.resolveColor(nextColor) || 'rgb(250, 250, 250)';
    const hasBackground = isBackgroundInsteadOfColor(bgColor);

    return (
      <EditContext.Provider value={{ language, languages, onChange: this.handleChange }}>
        <section
          className={cx(className, `section--${type}`, {
            'is-full-width': isFullWidth,
            'section--dark': bgColor && extractColorLuma(bgColor) < 128,
          })}
          id={isPreview ? `preview-${_id}` : `section-${_id}`}
          style={{
            position: 'relative',
            width: '100%',
            // overflow: "hidden",
            background: hasBackground ? bgColor : undefined,
            backgroundColor: hasBackground ? undefined : bgColor,
            ...this.getBackground(),
            ...containerStyle,
          }}
        >
          <div
            id={`anchor-${name.replace(/[^a-zA-Z0-9]/g, '-')}`}
            style={{ position: 'absolute', top: -50 }}
          />
          {!!opacity && <div className="translucent" style={{ opacity }} />}
          <div style={{ position: 'absolute', top: 8, right: 8 }}>
            {isEditing && editConfig && StyleDropdown && (
              <StyleDropdown
                id={_id}
                editConfig={editConfig}
                value={block}
                language={language}
                languages={languages}
              />
            )}
          </div>
          {hasHeader && (
            <div className="container">
              <SectionHeader
                title={title}
                subtitle={subtitle}
                defaultStyle={{ textAlign: 'center' }}
                isEditing={isEditing}
              />
              {children}
            </div>
          )}
          {!hasHeader && children}
          {divider && <Divider {...divider} color={shapeColor} style={styles.bottom} />}
        </section>
      </EditContext.Provider>
    );
  }
}

BaseSection.defaultProps = {
  hasHeader: true,
  className: 'section',
  name: '',
};

const BaseSectionWithContext = withConfigs(withEdit(withDesign(BaseSection)));
BaseSectionWithContext.Header = SectionHeader;

export default BaseSectionWithContext;
