import React, { PureComponent } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import { LinkButton } from '../components';
import { withEdit } from '../Context';

class HeroTextSection extends PureComponent {
  render() {
    const {
      buttonColor,
      buttonText,
      components,
      isEditing,
      parallax,
      size,
      subtitle,
      title,
      titlePosition,
      url,
      textPosition,
      backgroundImage,
      ...props
    } = this.props;
    const { RichText, FigureImage } = components;
    const titleEl = (
      <h1 key="title" className="title is-1">
        <RichText html={title} name="title" placeholder="Add title" />
      </h1>
    );
    const subtitleEl = (
      <h2 key="subtitle" className="subtitle is-3">
        <RichText html={subtitle} name="subtitle" placeholder="Add subtitle" />
      </h2>
    );
    return (
      <BaseSection
        className={cx(
          'hero',
          size !== 'image-size' && `is-${size}`,
          { 'is-dark': !!backgroundImage },
          { parallax: parallax === 'fullpage' },
        )}
        {...props}
        backgroundImage={size !== 'image-size' ? backgroundImage : undefined}
        block={this.props}
        hasHeader={false}
        isEditing={isEditing}
      >
        {size === 'image-size' && <FigureImage src={backgroundImage} />}
        <div className={cx('hero-body', size === 'image-size' && 'hero-body--absolute')}>
          <div className={cx('container')} style={{ textAlign: 'center' }}>
            <div className={cx(textPosition !== 'center' && `container--half-${textPosition}`)}>
              {titlePosition === 'top' && [titleEl, subtitleEl]}
              {titlePosition === 'bottom' && [subtitleEl, titleEl]}
              {url && (
                <div>
                  <LinkButton
                    url={url}
                    text={buttonText}
                    buttonColor={buttonColor}
                    isEditing={isEditing}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </BaseSection>
    );
  }
}

HeroTextSection.defaultProps = {
  isEditing: false,
  backgroundImage: undefined,
  buttonColor: '@primaryColor',
  buttonText: "S'inscrire",
  parallax: 'none',
  size: 'medium',
  subtitle: undefined,
  text: undefined,
  textPosition: 'center',
  title: undefined,
  titlePosition: 'top',
  url: undefined,
};

HeroTextSection.propTypes = {
  isEditing: PropTypes.bool,
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  components: PropTypes.object.isRequired,
  parallax: PropTypes.oneOf(['', 'none', 'fullpage']),
  size: PropTypes.oneOf(['normal', 'medium', 'large', 'fullheight', 'image-size']),
  subtitle: PropTypes.string,
  text: PropTypes.string,
  textPosition: PropTypes.oneOf(['left', 'center', 'right']),
  title: PropTypes.string,
  titlePosition: PropTypes.oneOf(['top', 'bottom']),
  url: PropTypes.string,
};

export default withComponents(withEdit(HeroTextSection));
