import { useEffect, useState } from 'react';

export function useMount(callback) {
  useEffect(callback, []);
}

export const useHasClientSideChecks = () => {
  // TODO: disable if not doing SSR (ie check if window.__DATA__ and no ssr enabled ?)
  const [, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return false;
};
