import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Media, Figure } from '../bulma';

export default class Speakers extends PureComponent {
  render() {
    const { showSpeakers, template, speakers } = this.props;
    if (!showSpeakers || speakers.length === 0) return null;
    const isBubble = template === 'bubble';
    return (
      <div className={cx('speakers', template)}>
        {speakers
          .filter((speaker) => !isBubble || speaker.thumbnail)
          .map(({ thumbnail, firstName, lastName, role, company, _id }, index) => (
            <div className="speaker" key={_id}>
              {isBubble ? (
                <Figure
                  src={thumbnail}
                  borderRadius="rounded"
                  format="is-64x64"
                  title="thumbnail"
                  className={index !== 0 ? 'left' : ''}
                  imgStyle={{ marginLeft: `-${index}em` }}
                />
              ) : (
                <Media
                  imageLeft={thumbnail}
                  imageProps={{ format: 'is-64x64' }}
                  title={`${firstName} ${lastName}`}
                  subtitle={`${role || ''} \n${company || ''}`}
                />
              )}
            </div>
          ))}
      </div>
    );
  }
}

Speakers.defaultProps = {
  showSpeakers: true,
  speakers: [],
  template: 'list',
};

Speakers.propTypes = {
  showSpeakers: PropTypes.bool,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  template: PropTypes.string,
};
