import React, { PureComponent } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { computeCDNUrl } from './FigureImage';
import { figureImageProps, loadingType } from '../utils/propTypes';

class Image extends PureComponent {
  renderImage() {
    const {
      src,
      isGallery,
      onClick,
      className,
      imageProps,
      loading,
      style,
      format,
      maxWidth,
      square,
    } = this.props;
    const url = computeCDNUrl({ maxWidth, src, square, type: get(imageProps, 'type', 'original') });
    if (isGallery) {
      return (
        <div
          className={cx('image--gallery', format && `image--${format.replace('/', '_')}`)}
          onClick={onClick}
          style={{
            backgroundImage: `url(${url})`,
          }}
        />
      );
    }
    return (
      <img
        loading={loading}
        className={cx('image', className)}
        src={url}
        alt={src && src.alt}
        style={{ ...style }}
      />
    );
  }

  render() {
    const { link } = this.props;

    if (link) {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          <div className="image-container">{this.renderImage()}</div>
        </a>
      );
    }

    return <div className="image-container">{this.renderImage()}</div>;
  }
}

Image.defaultProps = {
  className: '',
  isGallery: false,
  format: undefined,
  imageProps: {},
  link: undefined,
  loading: undefined,
  maxWidth: undefined,
  onClick: undefined,
  square: undefined,
  style: {},
  src: undefined,
};
Image.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  imageProps: figureImageProps,
  isGallery: PropTypes.bool,
  link: PropTypes.string,
  loading: loadingType,
  maxWidth: PropTypes.number,
  onClick: PropTypes.func,
  square: PropTypes.bool,
  style: PropTypes.object,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Image;
