import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { videoUrl } from '../utils/video';

const Video = React.memo(({ url, format, disableUrlConversion }) => {
  if (url.indexOf('.mp4') !== -1) {
    // Direct link to mp4 video...
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video width="1920" height="1080" preload controls>
        <source src={url} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
      </video>
    );
  }

  return (
    <div className={cx('video-container image', format)}>
      <iframe
        title="Video Player"
        src={disableUrlConversion ? url : videoUrl(url)}
        allowFullScreen
        frameBorder="0"
        loading="lazy"
      />
    </div>
  );
});

Video.defaultProps = {
  disableUrlConversion: false,
  format: 'is-16by9',
  url: 'https://www.youtube.com/embed/88daTIiU4Ik?rel=0',
};

Video.propTypes = {
  disableUrlConversion: PropTypes.bool,
  format: PropTypes.string,
  url: PropTypes.string,
};

export default Video;
