import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { replaceDataValues } from '../../utils';
import './collapsible.scss';
import { Card } from '../../bulma';
import Speakers from '../Speakers';
import WorkshopDetailsModal from '../WorkshopDetailsModal';
import {
  WorkshopUserCount,
  WorkshopCTA,
  WorkshopDetailsButton,
  WorkshopDate,
  WorkshopDescription,
  WorkshopTitle,
  WorkshopLang,
  WorkshopCategory,
} from './components';
import {
  workshopDefaultPropTypes,
  workshopPropTypes,
  workshopsDefaultPropTypes,
  workshopsPropTypes,
} from './workshop-proptypes';
import { Grid } from '../../components';
import Images from '../../utils/Images';

class Workshop extends PureComponent {
  state = {
    isActive: false,
    show: false,
  };

  toggleShow = () => {
    const { onClick } = this.props;
    this.setState(({ show }) => ({ show: !show }));
    if (onClick) {
      onClick();
    }
  };

  render() {
    const {
      ctaText,
      ctaUrl,
      labelMapping,
      workshop,
      titleTemplate,
      descriptionTemplate,
      dateFormat,
      showUserCount,
      showDetails,
      showSpeakers,
      hideSpeakerThumbnails,
      detailsLabel,
      userCountLabel,
      show,
      zIndex,
    } = this.props;
    const { isActive } = this.state;
    const { startDate, endDate, userCount, category, lang, image, speakers } = workshop;
    const title = replaceDataValues(titleTemplate, workshop).trim();
    const description = replaceDataValues(descriptionTemplate, workshop);
    const categoryLabel = get(labelMapping, ['category', category], category);
    return (
      <React.Fragment>
        <Card className={cx('card workshop', { show })} style={{ zIndex: show ? zIndex : 0 }}>
          {Images.exists(image) && (
            <Card.Image
              {...Images.srcSet.maxWidth(image, 500)}
              loading="lazy"
              alt={title}
              format="is-16by9"
            />
          )}
          <Card.Content>
            <div className="workshop--content">
              <div>
                <WorkshopDate startDate={startDate} endDate={endDate} format={dateFormat} />
              </div>
              <div className="header">
                {/* <WorkshopDate startDate={startDate} endDate={endDate} format={dateFormat} /> */}
                <WorkshopLang lang={lang} />
                <WorkshopCategory category={categoryLabel} />
              </div>
              <div style={{ display: 'flex', marginBottom: 8 }}>
                <WorkshopTitle title={title} />
                <div className="chevron" onClick={this.toggleShow}>
                  <span className="left" />
                  <span className="right" />
                </div>
              </div>

              {!show && !hideSpeakerThumbnails && (
                <Speakers speakers={speakers} showSpeakers={showSpeakers} template="bubble" />
              )}
            </div>
            <div className="card-flap">
              <div>
                <WorkshopCTA workshop={workshop} text={ctaText} url={ctaUrl} />
              </div>
              <WorkshopDescription description={description} />
              {show && <Speakers speakers={speakers} showSpeakers={showSpeakers} template="list" />}
              <div className="footer">
                <WorkshopUserCount
                  showUserCount={showUserCount}
                  userCount={userCount}
                  userCountLabel={userCountLabel}
                />
                <WorkshopDetailsButton
                  showDetails={showDetails}
                  detailsLabel={detailsLabel}
                  onClick={() => this.setState({ isActive: true })}
                />
              </div>
            </div>
          </Card.Content>
        </Card>
        <WorkshopDetailsModal
          isActive={isActive}
          onClose={() => this.setState({ isActive: false })}
          workshop={workshop}
          title={title}
          description={description}
        />
      </React.Fragment>
    );
  }
}

Workshop.defaultProps = {
  ...workshopDefaultPropTypes,
  show: false,
};

Workshop.propTypes = {
  ...workshopPropTypes,
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

// eslint-disable-next-line import/prefer-default-export
export class Collapsible extends PureComponent {
  constructor(props) {
    super(props);
    const { workshops } = props;
    this.state = {
      zIndex: workshops.length,
      isShowing: false,
      selected: undefined,
    };
  }

  render() {
    const { workshops, workshopConfig, gridSize } = this.props;
    const { zIndex, isShowing, selected } = this.state;
    return (
      <div className={cx('collapsible-cards', { showing: isShowing })}>
        <Grid items={workshops} gridSize={gridSize} tabletGridSize={Math.round(gridSize / 2)}>
          {(workshop, index) => {
            const isSelected = selected === index;
            return (
              <Workshop
                key={index}
                {...workshopConfig}
                titleTemplate={workshopConfig.title}
                descriptionTemplate={workshopConfig.description}
                workshop={workshop}
                onClick={() =>
                  this.setState((prevState) => ({
                    isShowing: !isSelected,
                    selected: isSelected ? undefined : index,
                    zIndex: prevState.zIndex + 1,
                  }))
                }
                show={isSelected}
                zIndex={zIndex}
              />
            );
          }}
        </Grid>
      </div>
    );
  }
}

Collapsible.defaultProps = {
  ...workshopsDefaultPropTypes,
  gridSize: 3,
};

Collapsible.propTypes = {
  ...workshopsPropTypes,
  gridSize: PropTypes.number,
};
