import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { BackToTopArrow, RichText, List, Grid, FAIcon, Image, FigureImage } from './components';
import './App.scss';

import { DesignContext, withAppContext } from './Context';

import { ComponentContext } from './components/Context';

import MobileFooterButton from './components/MobileFooterButton';
import Site from './Site';
import { routerLocationProps, appDataProps } from './utils/propTypes';
import { getCurrentPath } from './utils/pages';

let Styles;
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  Styles = require('./Styles').default;
}

export const viewerComponents = {
  RichText,
  List,
  Grid,
  FAIcon,
  Image,
  FigureImage,
};

class App extends Component {
  componentDidUpdate(prevProps) {
    // Scroll to top on page change
    const { location } = this.props;
    if (location !== prevProps.location) {
      // Compare has
      if (!location.hash) {
        // eslint-disable-next-line no-restricted-globals
        window.scrollTo(0, 0);
      }
    }
  }

  getActivePage() {
    const { site, location } = this.props;
    return site.pages.find((p) => p.link === location.pathname) || site.pages[0];
  }

  handleLanguageChange = (lang) => {
    const { data } = this.props;
    // eslint-disable-next-line no-restricted-globals
    window.location = getCurrentPath(data.rooturl, this.getActivePage(), { name: lang });
  };

  render() {
    const { site, lang, disableRouter } = this.props;
    const { menu = {} } = site;
    const { showMobileFooterButton, mobileFooterButtonSettings } = menu;
    return (
      <DesignContext.Provider value={site.design}>
        <React.Fragment>
          {Styles ? <Styles {...site.design} /> : undefined}
          <ComponentContext.Provider value={viewerComponents}>
            <Site
              disableRouter={disableRouter}
              site={site}
              language={lang}
              onLanguageChange={this.handleLanguageChange}
            />
          </ComponentContext.Provider>
          <BackToTopArrow />
          {showMobileFooterButton && <MobileFooterButton {...mobileFooterButtonSettings} />}
        </React.Fragment>
      </DesignContext.Provider>
    );
  }
}

App.defaultProps = {
  disableRouter: false,
  lang: undefined,
};

App.propTypes = {
  data: appDataProps.isRequired,
  disableRouter: PropTypes.bool,
  lang: PropTypes.string,
  site: PropTypes.object.isRequired,
  location: routerLocationProps.isRequired,
};

export default withAppContext(withRouter(App));
