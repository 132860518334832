import React from 'react';
import PropTypes from 'prop-types';
import Social from './Social';
import { withDesign, withEdit } from '../Context';
import { languageProps } from '../utils/propTypes';
import Button from './Button';

const HeaderMenu = (props) => {
  const { design, invertColors, languages, settings, language, onLanguageChange } = props;
  const { primaryColor } = design;
  const { socialMedia = [], button } = settings;
  const colorizedSocialMedia = socialMedia.map((social) => ({
    ...social,
    color: !invertColors ? 'white' : primaryColor,
  }));
  const hasLanguages = languages.length > 0;
  return (
    <div className="navbar-item menu-settings">
      <div
        className="header-menu--container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: hasLanguages ? 'space-between' : 'flex-end',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Social
            disableEdit
            className="header-menu--social-media"
            socialmedia={colorizedSocialMedia}
            language={language}
            invertColors={invertColors}
          />
          {hasLanguages && (
            <div className="select header-menu--language">
              <select
                name="lang"
                id="lang"
                value={language || languages[0].name}
                onChange={(e) => onLanguageChange(e.target.value)}
              >
                {languages.map((lng) => {
                  const { label, name } = lng; // TODO : handle url
                  return (
                    <option key={name} value={name}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
        {button && button.url && (
          <Button
            as="a"
            target="_blank"
            href={button.url}
            style={{ color: primaryColor }}
            className="header-menu--button"
            {...button}
          />
        )}
      </div>
    </div>
  );
};

HeaderMenu.defaultProps = {
  invertColors: false,
  language: undefined,
  languages: [],
  settings: {},
};

HeaderMenu.propTypes = {
  design: PropTypes.object.isRequired,
  invertColors: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.arrayOf(languageProps),
  onLanguageChange: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    button: PropTypes.shape({
      content: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
    socialMedia: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.string,
        url: PropTypes.string,
        color: PropTypes.string,
      }),
    ),
  }),
};

export default withEdit(withDesign(HeaderMenu));
