import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import scrollIntoView from 'scroll-into-view-if-needed';
import { withRouter } from 'react-router-dom';
import { withComponents } from './Context';
import { withEdit, EditContext } from '../Context';
import { FAIcon, NavLinkWithParams } from '.';

class MenuLeft extends PureComponent {
  handleScrollIntoView = (title) => {
    // eslint-disable-next-line no-restricted-globals
    const node = document.getElementById(title);
    scrollIntoView(node, {
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  };

  handleChange = (key, value) => {
    const { onChange } = this.props;
    if (key === 'logoLeft') {
      onChange('logoLeft', key, value ? value.uri : '');
    } else onChange('menu', key, value);
  };

  render() {
    const { components, logoLeft, menu = {}, pages } = this.props;
    const { RichText, Image } = components;
    const { showIcons = false, subtitleTop, subtitleBottom, verticalAlign, horizontalAlign } = menu;
    // const active = isActive ? "is-active" : "";
    return (
      <EditContext.Provider value={{ onChange: this.handleChange }}>
        <nav className={cx('menu is-fixed-left', `v-${verticalAlign}`, `h-${horizontalAlign}`)}>
          <div className="menu-brand">
            <Image src={logoLeft} name="logoLeft" isUnsplash={false} />
          </div>

          <div className="menu-subtitle menu-subtitle--top">
            <RichText html={subtitleTop} name="subtitleTop" placeholder="Add subtitle" />
          </div>
          <ul className="menu-list">
            {pages
              .filter((p) => p.type !== 'subpage' && p.type !== 'hidden')
              .map((submenu, index) => {
                const { title, icon, link, type = 'page' } = submenu;
                if (type === 'link') {
                  return (
                    <a activeClassName="is-active" href={link} target="_blank" rel="noreferrer">
                      {showIcons && (
                        <span className="icon">
                          <FAIcon icon={icon} />
                        </span>
                      )}
                      <span>{title}</span>
                    </a>
                  );
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <NavLinkWithParams
                      to={link}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      activeClassName="is-active"
                      exact
                      onClick={this.closeBurgerMenu}
                    >
                      {showIcons && (
                        <span className="icon" style={{ marginRight: 4 }}>
                          <FAIcon icon={icon} />
                        </span>
                      )}
                      <span>{title}</span>
                    </NavLinkWithParams>
                  </li>
                );
              })}
          </ul>
          <div className="menu-subtitle menu-subtitle--bottom">
            <RichText html={subtitleBottom} name="subtitleBottom" placeholder="Add subtitle" />
          </div>
        </nav>
      </EditContext.Provider>
    );
  }
}

MenuLeft.defaultProps = {
  components: {},
  onChange: noop,
  logoLeft: '',
  menu: {},
  pages: [],
};

MenuLeft.propTypes = {
  components: PropTypes.object,
  onChange: PropTypes.func,
  logoLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  menu: PropTypes.object,
  pages: PropTypes.array,
};

export default withEdit(withRouter(withComponents(MenuLeft)));
