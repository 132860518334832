/* eslint-disable operator-linebreak */
/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import { withEdit } from '../Context';

const EditableSpan = ({ name, value, onChange, ...props }) => (
  <span {...props}>
    <input name={name} value={value} onChange={onChange} type="text" />
  </span>
);

EditableSpan.defaultProps = {
  value: undefined,
};

EditableSpan.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

class TabsSection extends Component {
  state = {
    activeIndex: 0,
  };

  handleSelect = (e, activeIndex) => {
    e.preventDefault();
    this.setState({ activeIndex });
  };

  handleTabChange = (e) => {
    const { _id, onChange } = this.props;
    const { name, value } = e.target;
    onChange(_id, name, value);
  };

  render() {
    const { components, tabs, isPreview, onChange, template, isEditing, ...props } = this.props;
    const { activeIndex } = this.state;
    const { Image } = components;

    const hiddenTabs = tabs.filter((t, index) => index !== activeIndex);
    return (
      <BaseSection
        {...props}
        block={this.props}
        containerStyle={{ paddingBottom: 0 }}
        isPreview={isPreview}
      >
        <div className={cx('tabs is-centered', template === 'image' && 'is-fullwidth')}>
          <ul>
            {tabs.map((tab, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className={activeIndex === index ? 'is-active' : ''}>
                {isEditing ? (
                  <a onClick={(e) => this.handleSelect(e, index)}>
                    <div className="tab--container">
                      {template === 'image' && (
                        <Image src={tab.image} name={`tabs[${index}].image`} />
                      )}
                      <EditableSpan
                        className="c-editable-span c-text--desktop"
                        name={`tabs[${index}].title`}
                        onChange={this.handleTabChange}
                        value={tab.title}
                      />
                      <EditableSpan
                        className="c-editable-span c-text--mobile"
                        name={`tabs[${index}].mobileTitle`}
                        onChange={this.handleTabChange}
                        value={tab.mobileTitle}
                      />
                    </div>
                  </a>
                ) : (
                  <a onClick={(e) => this.handleSelect(e, index)}>
                    <div className="tab--container">
                      {template === 'image' && <Image src={tab.image} />}
                      <span className="c-text--desktop">{tab.title}</span>
                      <span className="c-text--mobile">{tab.mobileTitle}</span>
                    </div>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            
            ${tabs.map((t) => `#section-${t.sectionId} { padding-top: 0; }`).join('\n')}
            ${
              isPreview
                ? ''
                : hiddenTabs.map((t) => `#section-${t.sectionId} { display: none; }`).join('\n')
            }
            `,
          }}
        />
      </BaseSection>
    );
  }
}

TabsSection.defaultProps = {
  isEditing: false,
  isPreview: false,
  tabs: [],
  template: 'text',
};

TabsSection.propTypes = {
  _id: PropTypes.string.isRequired,
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  isPreview: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      mobileTitle: PropTypes.string,
      sectionId: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
  template: PropTypes.oneOf(['text', 'image']),
};

export default withEdit(withComponents(TabsSection));
