import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { formFields } from '../components/ContactForm';
import { Columns, Column, ContactForm } from '../components';

export default class ContactSection extends PureComponent {
  render() {
    const { fields, strings, ...props } = this.props;
    // TODO : use fields to filter formFields
    return (
      <BaseSection {...props} block={this.props}>
        <Columns isCentered>
          <Column size={9}>
            <ContactForm fields={Array.isArray(fields) ? fields : formFields} strings={strings} />
          </Column>
        </Columns>
      </BaseSection>
    );
  }
}

ContactSection.defaultProps = {
  fields: undefined,
  strings: {},
};

ContactSection.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({ name: PropTypes.string, type: PropTypes.string }),
        ),
      }),
    ),
  ]),
  strings: PropTypes.object,
};
