import React from 'react';
import cx from 'classnames';

class Menu extends React.Component {
  render() {
    const { children } = this.props;
    return <aside className="menu">{children}</aside>;
  }
}

Menu.Item = ({ children, ...props }) => <div {...props}>{children}</div>;
Menu.Header = ({ children, className, ...props }) => (
  <p {...props} className={cx('menu-label', className)}>
    {children}
  </p>
);
Menu.Menu = ({ children, className, ...props }) => (
  <ul {...props} className={cx('menu-list', className)}>
    {children}
  </ul>
);

Menu.Menu.Item = ({ children, name, active, className, ...props }) => (
  <li>
    <a className={cx(className, active && 'is-active')} {...props}>
      {children || name}
    </a>
  </li>
);

export default Menu;
