/* eslint-disable global-require */
if (process.env.REACT_APP_BUILD_TARGET !== 'server') {
  // Don't import polyfills if on the server
  require('react-app-polyfill/ie9');
  require('core-js/fn/array/find');
  require('core-js/fn/array/includes');
  require('core-js/fn/number/is-nan');
  require('core-js/fn/string/starts-with');
  require('core-js/es6/map');
  require('core-js/es6/set');
  require('core-js/shim');

  const smoothscroll = require('smoothscroll-polyfill');
  // kick off the polyfill!
  smoothscroll.polyfill();
}

if (process.env.REACT_APP_BUILD_TARGET === 'server') {
  // Polyfill window
  // if (typeof window === 'undefined') {
  //   console.log('POLYFILL WINDOW');
  //   global.window = {
  //     location: {
  //       protocol: 'https://',
  //       host: '',
  //       href: 'https://',
  //       search: '',
  //     },
  //   };
  // }
}

if (process.env.REACT_APP_BUILD_TARGET === 'editor') {
  require('./indexEditor');
} else if (process.env.REACT_APP_BUILD_TARGET === 'server') {
  // TODO: find way to export rather than push to global...
  global.renderWebsite = require('./indexServer').renderWebsite;
} else {
  require('./indexViewer');
}
