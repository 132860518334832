import cx from 'classnames';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import BaseSection from './BaseSection';
import { withDesign, withAppContext } from '../Context';
import * as templates from '../items';
import { Grid } from '../components';
import { appDataProps } from '../utils/propTypes';

class DataItemSection extends PureComponent {
  getItems() {
    const { data, source } = this.props;
    if (!source) return [];
    const { dataType, collection, category } = source;
    if (!dataType || !collection) return []; // No data to show ?

    const items = get(data, ['data', dataType, collection]);
    if (!items) {
      // TODO: auto-fetch if missing ? Hide ?
      return [];
    }
    if (category) return items.filter((item) => item.category === category);
    return items;
  }

  render() {
    const { className, gridSize, itemConfig, gridGapless, source, ...props } = this.props;

    const items = this.getItems();

    const orderedItems = orderBy(items, itemConfig.orderBy);

    const Item = templates[itemConfig.component] || templates.CardItem;
    const isHexagonal = itemConfig.component === 'HexagonalItem';
    return (
      <BaseSection
        {...props}
        className={cx(
          className,
          `section section--DataItemSection--${source.dataType}-${source.collection}`,
        )}
        block={this.props}
      >
        <Grid
          items={orderedItems}
          gridSize={gridSize}
          isCentered
          tabletGridSize={Math.round(gridSize / 2)}
          // equalHeight
          isHexagonal={isHexagonal}
          isGapless={gridGapless}
        >
          {(item) => (
            <Item
              key={item._id}
              item={item}
              textAlign={itemConfig.textAlign}
              imageField={itemConfig.image}
              imageProps={itemConfig.imageProps}
              titleTemplate={itemConfig.title}
              subtitleTemplate={itemConfig.subtitle}
              descriptionTemplate={itemConfig.description}
              linkField={itemConfig.link}
              linkLabelTemplate={itemConfig.linkLabel}
            />
          )}
        </Grid>
      </BaseSection>
    );
  }
}

DataItemSection.defaultProps = {
  className: '',
  gridGapless: false,
  gridSize: 4,
  source: {},
  itemConfig: {
    textAlign: 'left',
    orderBy: undefined,
  },
};

DataItemSection.propTypes = {
  className: PropTypes.string,
  data: appDataProps.isRequired,
  gridGapless: PropTypes.bool,
  gridSize: PropTypes.number,
  source: PropTypes.shape({
    dataType: PropTypes.string.isRequired,
    collection: PropTypes.string.isRequired,
    category: PropTypes.string,
  }),
  itemConfig: PropTypes.shape({
    component: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    orderBy: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default withAppContext(withDesign(DataItemSection));
