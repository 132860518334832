import endsWith from 'lodash/endsWith';
import startsWith from 'lodash/startsWith';
import { stringify } from 'query-string';

const aliases = {
  'https://app.appcraft.events/images': '_appcraft_images_',
  'https://app.sandbox.appcraft.events/images': '_sandbox_images_',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events':
    'evt',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static1':
    '_storage_static1_',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001':
    '_storage_static001_',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/sandbox001':
    '_storage_sandbox001_',
  'https://avfuefcxen.cloudimg.io/cdn/n/n/': '',
};

const aliasUrls = Object.keys(aliases);

export function shorthand(url) {
  const aliasUrl = aliasUrls.find((prefix) => url.indexOf(prefix) === 0);
  if (startsWith(url, 'https://avfuefcxen.cloudimg.io/v7/')) {
    // Already shorthanded, extract relevant part
    return url.replace(
      /^https:\/\/avfuefcxen\.cloudimg\.io\/v7\/([^?]+)\?.*/,
      (_all, group1) => group1,
    );
  }
  if (aliasUrl) {
    let shortUrl = url;
    if (
      (endsWith(shortUrl, '.jpg') || endsWith(shortUrl, '.jpeg')) &&
      shortUrl.indexOf('-original') === -1 &&
      // Only use "original" for asset files. Photo album images don't have -original !
      shortUrl.indexOf('assets') !== -1
    ) {
      // Inject -original
      shortUrl = shortUrl.replace('.jpg', '-original.jpg').replace('.jpeg', '-original.jpeg');
    }
    return shortUrl.replace(aliasUrl, aliases[aliasUrl]);
  }
  return url;
}

export function getUrl(uri) {
  return uri && (typeof uri === 'string' ? uri : uri.uri);
}

export function cloudimg(uri, options) {
  const url = getUrl(uri);
  if (!url) return url;

  // disable for unsplash.com
  if (url.indexOf('unsplash.com') !== -1) {
    return url;
  }

  return `https://avfuefcxen.cloudimg.io/v7/${shorthand(url)}?${stringify(options)}`;
}

function cdn(uri) {
  const url = getUrl(uri);
  if (!url) return url;

  // Already on the cdn ?
  if (startsWith(url, 'https://avfuefcxen.cloudimg.io')) {
    return url;
  }

  // disable for unsplash.com
  if (url.indexOf('unsplash.com') !== -1) {
    return url;
  }

  return `https://avfuefcxen.cloudimg.io/cdn/n/n/${shorthand(url)}`;
}

function extractFormat(type) {
  if (type === 'auto') return '';
  return type || 'original';
}

function maxWidth(image, maxW = 1200, options = {}) {
  return cloudimg(image, {
    w: maxW,
    func: 'bound',
    org_if_sml: 1,
    force_format: extractFormat(options.type),
  });
}

function maxHeight(image, maxH = 1000, options = {}) {
  return cloudimg(image, {
    h: maxH,
    func: 'bound',
    org_if_sml: 1,
    force_format: extractFormat(options.type),
  });
}

function square(image, size = 200, options = {}) {
  return cloudimg(image, {
    w: size,
    h: size,
    force_format: extractFormat(options.type),
  });
}

const srcSet = {
  maxWidth: (image, maxW, options) => ({
    src: maxWidth(image, maxW, options),
    srcSet: `${maxWidth(image, maxW * 2, options)} 2x`,
  }),
  maxHeight: (image, maxH, options) => ({
    src: maxHeight(image, maxH, options),
    srcSet: `${maxHeight(image, maxH * 2, options)} 2x`,
  }),
  square: (image, size, options) => ({
    src: square(image, size, options),
    srcSet: `${square(image, size * 2, options)} 2x`,
  }),
};

function exists(image) {
  return !!getUrl(image);
}
function generatePdfThumbnail(uri, { width, height, bgColor = 'fafafa', func = 'fit' }) {
  if (!uri) return null;
  const queryString = {
    force_format: 'jpg',
    func,
    bg_colour: bgColor,
    w: width,
    h: height || Math.round((width / 21) * 29.7),
  };
  return `http://avfuefcxen.cloudimg.io/v7/${shorthand(uri)}?${stringify(queryString)}`;
}

export default {
  shorthand,
  cdn,
  maxWidth,
  maxHeight,
  square,
  exists,
  srcSet,
  generatePdfThumbnail,
};
