import React, { PureComponent } from 'react';
import MasonryView, { ResponsiveMasonry } from 'react-responsive-masonry';
import get from 'lodash/get';
import { replaceDataValues } from '../../utils';
import WorkshopDetailsModal from '../WorkshopDetailsModal';
import Speakers from '../Speakers';
import './masonry.scss';
import {
  WorkshopUserCount,
  WorkshopDetailsButton,
  WorkshopDate,
  WorkshopTitle,
  WorkshopDescription,
  WorkshopLang,
  WorkshopCategory,
} from './components';
import {
  workshopDefaultPropTypes,
  workshopPropTypes,
  workshopsDefaultPropTypes,
  workshopsPropTypes,
} from './workshop-proptypes';

class Workshop extends PureComponent {
  state = { isActive: false };

  render() {
    const { isActive } = this.state;
    const {
      labelMapping,
      workshop,
      titleTemplate,
      descriptionTemplate,
      dateFormat,
      showUserCount,
      showDetails,
      showSpeakers,
      speakersTemplate,
      detailsLabel,
      userCountLabel,
    } = this.props;
    const { startDate, endDate, userCount, category, lang, speakers } = workshop;
    const title = replaceDataValues(titleTemplate, workshop).trim();
    const description = replaceDataValues(descriptionTemplate, workshop);
    const categoryLabel = get(labelMapping, ['category', category], category);
    return (
      <div className="box workshop">
        <div className="workshop--content" style={{ display: 'block' }}>
          <div style={{ flex: 1, marginBottom: 8 }}>
            <div className="header">
              <WorkshopLang lang={lang} />
              <WorkshopCategory category={categoryLabel} />
            </div>
            <WorkshopTitle title={title} />
            <WorkshopDate startDate={startDate} endDate={endDate} format={dateFormat} />
            <WorkshopDescription description={description} />
            <Speakers speakers={speakers} showSpeakers={showSpeakers} template={speakersTemplate} />
          </div>
          <div className="footer">
            <WorkshopUserCount
              showUserCount={showUserCount}
              userCount={userCount}
              userCountLabel={userCountLabel}
            />
            <WorkshopDetailsButton
              showDetails={showDetails}
              detailsLabel={detailsLabel}
              onClick={() => this.setState({ isActive: true })}
            />
            <WorkshopDetailsModal
              isActive={isActive}
              onClose={() => this.setState({ isActive: false })}
              workshop={workshop}
              title={title}
              description={description}
            />
          </div>
        </div>
      </div>
    );
  }
}

Workshop.defaultProps = workshopDefaultPropTypes;
Workshop.propTypes = workshopPropTypes;

// eslint-disable-next-line import/prefer-default-export
export class Masonry extends PureComponent {
  renderWorkshop(workshop) {
    const { workshopConfig } = this.props;
    return (
      <Workshop
        key={workshop._id}
        {...workshopConfig}
        titleTemplate={workshopConfig.title}
        descriptionTemplate={workshopConfig.description}
        workshop={workshop}
      />
    );
  }

  render() {
    const { workshops } = this.props;
    return (
      <ResponsiveMasonry>
        <MasonryView gutter={20}>
          {workshops.map((workshop) => this.renderWorkshop(workshop))}
        </MasonryView>
      </ResponsiveMasonry>
    );
  }
}

Masonry.defaultProps = workshopsDefaultPropTypes;
Masonry.propTypes = workshopsPropTypes;
