/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import padStart from 'lodash/padStart';

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
    ' ',
  );

  return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
  return ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

const SVGCircle = ({ radius, stroke }) => (
  <svg className="countdown-svg">
    <path fill="none" style={{ stroke }} strokeWidth="4" d={describeArc(50, 50, 44, 0, radius)} />
  </svg>
);

const CircleProgress = ({ color, value, label, maxValue }) => (
  <div className="countdown-item" style={{ color }}>
    <SVGCircle radius={mapNumber(value, maxValue, 0, 360, 0)} stroke={color} />
    {padStart(value, 2, '0')}
    <span>{label}</span>
  </div>
);

CircleProgress.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
};

const CountdownCircle = React.memo(({ timeLeft, color, labels }) => {
  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div>
      <div className="countdown-wrapper">
        <CircleProgress label={labels.days || 'jours'} value={days} color={color} maxValue={360} />
        <CircleProgress
          label={labels.hours || 'heures'}
          value={hours}
          color={color}
          maxValue={24}
        />
        <CircleProgress
          label={labels.minutes || 'minutes'}
          value={minutes}
          color={color}
          maxValue={60}
        />
        <CircleProgress
          label={labels.seconds || 'secondes'}
          value={seconds}
          color={color}
          maxValue={60}
        />
      </div>
    </div>
  );
});

export default CountdownCircle;

CountdownCircle.defaultProps = {
  color: undefined,
  labels: {},
};
CountdownCircle.propTypes = {
  color: PropTypes.string,
  labels: PropTypes.shape({
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string,
  }),
  timeLeft: PropTypes.shape({
    days: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
  }).isRequired,
};
