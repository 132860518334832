import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import noop from 'lodash/noop';
import scrollIntoView from 'scroll-into-view-if-needed';
import HeaderMenu from './HeaderMenu';
import { withComponents } from './Context';
import { withEdit, EditContext, withAppContext } from '../Context';
import Images from '../utils/Images';
import { FAIcon, NavLinkWithParams } from '.';
import { iconProps, languageProps, appDataProps } from '../utils/propTypes';

export const MenuItem = ({ item, menu, onCloseBurgerMenu }) => {
  const { title, icon, link, type = 'page', menuButton = {} } = item;
  const { showIcons = false, buttonType = 'classic' } = menu;
  const { type: pageButtonType } = menuButton;
  const navMenuItemClass = `navbar-item--${pageButtonType || buttonType}`;
  const isExternalLink = type === 'link' || link.indexOf('http') === 0;
  // eslint-disable-next-line react/prop-types
  const Component = ({ children, ...rest }) =>
    isExternalLink ? (
      <a href={link} target="_blank" rel="noreferrer" onClick={onCloseBurgerMenu} {...rest}>
        {children}
      </a>
    ) : (
      <NavLinkWithParams to={link} exact {...rest} onClick={onCloseBurgerMenu}>
        {children}
      </NavLinkWithParams>
    );
  return (
    <Component
      className={cx('navbar-item', navMenuItemClass)}
      activeClassName="is-active"
      style={{ fontSize: 15 }}
    >
      {showIcons && (
        <span className="icon">
          <FAIcon icon={icon} />
        </span>
      )}
      <span>{title}</span>
    </Component>
  );
};

MenuItem.defaultProps = {};

MenuItem.propTypes = {
  onCloseBurgerMenu: PropTypes.func.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    icon: iconProps,
    link: PropTypes.string,
    type: PropTypes.oneOf(['link', 'page']),
    menuButton: PropTypes.shape({
      type: PropTypes.oneOf(['classic', 'tab', 'button']),
    }),
  }).isRequired,
  menu: PropTypes.shape({
    showIcons: PropTypes.bool,
    buttonType: PropTypes.oneOf(['classic', 'tab', 'button']),
  }).isRequired,
};

class Menu extends PureComponent {
  state = {
    isTop: true,
    isActive: false,
  };

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleToggleBurgerMenu = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  closeBurgerMenu = () => {
    this.setState({ isActive: false });
  };

  handleScrollIntoView = (title) => {
    // eslint-disable-next-line no-restricted-globals
    const node = document.getElementById(title);
    scrollIntoView(node, {
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  };

  handleScroll = () => {
    // eslint-disable-next-line no-restricted-globals
    const isTopWindow = window.scrollY === 0;
    const { isTop } = this.state;
    if (isTopWindow !== isTop) {
      this.setState({ isTop });
    }
  };

  handleChange = (key, value) => {
    const { onChange } = this.props;
    if (key === 'logo') {
      onChange('logo', key, value ? value.uri : '');
    } else if (key === 'menuTitle') {
      onChange('menu', key, value);
    }
  };

  enhanceURL = (url) => {
    const { data } = this.props;
    const { user } = data;
    if (user && user._id) {
      const id = user._id;
      if (url.indexOf('?') !== -1) {
        // Add to end
        return `${url}&u=${id}`;
      }
      return `${url}?u=${id}`;
    }
    return url;
  };

  render() {
    const {
      logo,
      pages,
      fixed,
      components,
      menu,
      languages,
      forceShowPages,
      isEditing,
      language,
      onLanguageChange,
    } = this.props;
    const { Image, RichText } = components;
    const {
      showPages = true,
      backgroundColor,
      invertColors,
      showHeaderMenu = false,
      headerMenuSettings = {},
    } = menu;
    const { isActive, isTop } = this.state;
    const active = isActive ? 'is-active' : '';
    const headerMenuShown = showHeaderMenu ? 'header-menu-is-shown' : '';

    // Only show if more than 1 page
    const mustShowPages =
      forceShowPages || (showPages && pages.filter((p) => p.type !== 'subpage').length > 1);
    const hasTranslations = menu.translations && menu.translations.length > 0;
    return (
      <EditContext.Provider value={{ language, onChange: this.handleChange }}>
        <nav
          id="navbar"
          className={cx('navbar', {
            'is-fixed-top': fixed,
            'is-at-top': isTop,
            invert: invertColors,
          })}
          style={{ backgroundColor }}
        >
          <div className="container">
            <div className="navbar-brand" style={{ width: mustShowPages ? 'auto' : '100%' }}>
              <NavLinkWithParams to="/" disabled={isEditing}>
                <Image src={logo} name="logo" alt="Logo" isUnsplash={false} />
              </NavLinkWithParams>
              <RichText
                html={menu.menuTitle || ''}
                name="menuTitle"
                placeholder="Add title"
                className=" menuTitle"
                style={{ width: mustShowPages || hasTranslations ? 'auto' : '100%' }}
              />
              {mustShowPages && (
                <span
                  aria-label="menu"
                  aria-expanded="false"
                  className={cx('navbar-burger', active)}
                  onClick={this.handleToggleBurgerMenu}
                >
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </span>
              )}
            </div>
            {mustShowPages && (
              <div className={cx('navbar-menu', active, headerMenuShown)}>
                <div className="navbar-end">
                  {pages
                    .filter((p) => p.type !== 'subpage' && p.type !== 'hidden')
                    .map((item) => (
                      <MenuItem
                        key={item._id}
                        item={item}
                        menu={menu}
                        onCloseBurgerMenu={this.closeBurgerMenu}
                      />
                    ))}
                </div>
                {showHeaderMenu && (
                  <HeaderMenu
                    language={language}
                    languages={languages}
                    invertColors={invertColors}
                    settings={headerMenuSettings}
                    onLanguageChange={onLanguageChange}
                  />
                )}
              </div>
            )}
            {hasTranslations && (
              <div className="navbar-end">
                <div className="navbar-item navbar-item--flags">
                  {menu.translations.map((trans, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <a href={this.enhanceURL(trans.url)} key={index}>
                      <img src={Images.maxWidth(trans.image, 300)} alt={`flag-${trans.lang}`} />
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </nav>
      </EditContext.Provider>
    );
  }
}

Menu.defaultProps = {
  fixed: true,
  forceShowPages: false,
  isEditing: false,
  language: undefined,
  languages: [],
  logo: undefined,
  menu: {},
  onChange: noop,
  pages: [],
};

Menu.propTypes = {
  data: appDataProps.isRequired,
  components: PropTypes.object.isRequired,
  fixed: PropTypes.bool,
  forceShowPages: PropTypes.bool,
  isEditing: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.arrayOf(languageProps),
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  menu: PropTypes.object,
  onChange: PropTypes.func,
  pages: PropTypes.array,
  onLanguageChange: PropTypes.func.isRequired,
};

export default withAppContext(withEdit(withRouter(withComponents(Menu))));
