import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Modal from '../editor/Modal';
import Button from './Button';

const allMedias = [
  { name: 'app-store-ios', icon: 'app-store-ios' },
  { name: 'android', icon: 'android' },
  { name: 'twitter', icon: 'twitter-square' },
  { name: 'facebook', icon: 'facebook-square' },
  { name: 'linkedin', icon: 'linkedin' },
  { name: 'instagram', icon: 'instagram' },
  { name: 'youtube', icon: 'youtube' },
];

export default class SocialNetworksModal extends PureComponent {
  constructor(props) {
    super(props);
    const { socialmedia } = props;
    this.state = {
      socialmedia: allMedias.map((s) => {
        const propSocial = socialmedia.find(
          (otherS) => otherS.name === s.name || otherS.icon === s.icon,
        );
        return {
          ...s,
          url: propSocial ? propSocial.url : '',
        };
      }),
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      socialmedia: prevState.socialmedia.map((s) => {
        if (s.name === name) {
          return {
            ...s,
            url: value,
          };
        }
        return s;
      }),
    }));
  };

  handleValidate = () => {
    const { name, onClose, onChange } = this.props;
    const { socialmedia } = this.state;
    onChange(
      name,
      socialmedia.filter((s) => s.url).map((s) => ({ ...s, color: 'white' })),
    );
    onClose();
  };

  renderSocial = (social) => {
    const { name, icon, url } = social;
    return (
      <div className="field" key={name}>
        <div className="control has-icons-left has-icons-right">
          <input
            className="input"
            type="email"
            value={url}
            placeholder={name}
            name={name}
            onChange={this.handleChange}
          />
          <span className="icon is-small is-left">
            <i className={`fab fa-${icon}`} />
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { onClose } = this.props;
    const { socialmedia } = this.state;
    return (
      <Modal
        title="Réseaux sociaux"
        onClose={onClose}
        footer={
          <footer className="modal-card-foot">
            <Button type="success" onClick={this.handleValidate}>
              Valider
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </footer>
        }
      >
        <div>{socialmedia.map(this.renderSocial)}</div>
      </Modal>
    );
  }
}

SocialNetworksModal.defaultProps = {
  name: '',
  socialmedia: [],
};

SocialNetworksModal.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  socialmedia: PropTypes.array,
};
