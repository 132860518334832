import React from 'react';
import RichText from './RichText';
import Grid from './Grid';
import FAIcon from './FAIcon';
import List from './List';
import Image from './Image';
import FigureImage from './FigureImage';

export const defaultComponents = {
  RichText,
  Grid,
  List,
  FAIcon,
  Image,
  FigureImage,
};
export const ComponentContext = React.createContext(defaultComponents);
export function withComponents(Component) {
  return function ComponentsComponent(props) {
    return (
      <ComponentContext.Consumer>
        {(components) => <Component {...props} components={components} />}
      </ComponentContext.Consumer>
    );
  };
}
