import setWith from 'lodash/setWith';
import set from 'lodash/set';
import get from 'lodash/get';
import clone from 'lodash/clone';
import omit from 'lodash/omit';

export function setIn(object, path, value) {
  return setWith(clone(object), path, value, clone);
}

export function patchIn(object, patch) {
  let obj = object;
  // eslint-disable-next-line guard-for-in
  for (const key in patch) {
    obj = setIn(obj, key, patch[key]);
  }
  return obj;
}

export function removeKey(object, path) {
  if (typeof object !== 'object') return object; // Can't do anything here
  if (typeof path === 'string') {
    if (path.indexOf('[') !== -1) {
      console.warn("removeKey doesn't handle array indices yet !!");
      return object;
    }
    return removeKey(object, path.split('.'));
  }
  const [key, ...rest] = path;
  if (rest.length === 0) {
    // Final key, time to remove
    return omit(object, key);
  }
  return {
    ...object,
    [key]: removeKey(clone(object[key]), rest),
  };
}

export function removePath(object, path) {
  // TODO : optimize
  const newObject = setIn(object, path, null);

  const shortPath = path.replace(/\[([^[]*)]$/, '');
  const parentPath = get(newObject, shortPath);
  if (parentPath) {
    // Clean array
    set(
      newObject,
      shortPath,
      parentPath.filter((v) => v),
    ); // Compact array
  } // else : nothing to cleaned, complete removed
  return newObject;
}

// Keep first url to capture variables
let baseUrl = '';
if (process.env.REACT_APP_BUILD_TARGET !== 'server') {
  // eslint-disable-next-line no-restricted-globals
  baseUrl = window.location.href;
}

export function getParameterByName(name, url) {
  // eslint-disable-next-line no-param-reassign
  if (!url) url = baseUrl;
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export function uuid() {
  let str = '';
  for (let i = 0; i < 14; i++) {
    // eslint-disable-next-line no-bitwise
    str += characters[(Math.random() * characters.length) | 0];
  }
  return str;
}

export function initIds(value) {
  if (!value) return;
  if (Array.isArray(value)) {
    value.forEach(initIds);
  } else if (typeof value === 'object') {
    if (!value._id) {
      // eslint-disable-next-line no-param-reassign
      value._id = uuid();
    }
    // eslint-disable-next-line guard-for-in
    for (const key in value) {
      // Don't inject _id in translations
      if (key !== 'translations') {
        initIds(value[key]);
      }
    }
  }
}

function upgradeSection(section) {
  if (section.type === 'ProgramSection') {
    if (section.itemList) {
      // Upgrade to multi-day support
      // eslint-disable-next-line no-param-reassign
      section.items = [section.itemList];
      // eslint-disable-next-line no-param-reassign
      delete section.itemList;
    }
  }
}

export function patchDefaultSite(site) {
  site.pages.forEach(initIds);
  // site.pages.forEach((p) => initIds(p.sections));
  site.pages.forEach((p) => p.sections.forEach(upgradeSection));
  if (!site.design) {
    // eslint-disable-next-line no-param-reassign
    site.design = {
      primaryColor: '#26B78A',
    };
  }
  if (site.footer && site.footer.length > 0) {
    const footer = site.footer[0];
    if (!footer._id) footer._id = 'footer';
    if (footer.children) {
      footer.blocks = footer.children;
      delete footer.children;
    }
  }
  initIds(site.meta);
  initIds(site.footer);
}

export function replaceValues(text) {
  return text.replace(/{(.*?)}/g, (match, key) => {
    if (key.indexOf('user.') === 0) {
      // SSR TODO: shouldn't use window here but probably shouldn't use SSR for user specific stuff...
      // eslint-disable-next-line no-restricted-globals
      const { user } = window.__DATA__;
      if (!user) return '';

      const userKey = key.replace('user.', '');
      return user[userKey] || '';
    }
    return getParameterByName(key) || '';
  });
}

function ensureString(v) {
  if (typeof v === 'number') return v.toString();
  return v || '';
}

export function replaceDataValues(text, item) {
  if (!text) return '';
  return text.replace(/{(.*?)}/g, (m, key) => ensureString(get(item, key)));
}

export function noop(e) {
  e.stopPropagation();
  e.preventDefault();
}
