import React from 'react';
import PropTypes from 'prop-types';
import { replaceDataValues } from '../utils';
import { Card, Media } from '../bulma';
import { imageProps as bulmaImageProps } from '../bulma/propTypes';
import Images from '../utils/Images';

// eslint-disable-next-line import/prefer-default-export
export class ImageLink extends React.PureComponent {
  render() {
    const {
      item,
      textAlign,
      imageField,
      imageProps,
      titleTemplate,
      subtitleTemplate,
      descriptionTemplate,
      linkField,
    } = this.props;
    const title = replaceDataValues(titleTemplate, item).trim();
    const subtitle = replaceDataValues(subtitleTemplate, item).trim();
    const description = replaceDataValues(descriptionTemplate, item).trim();
    const image = imageField && item[imageField];
    const link = linkField && item[linkField];
    return (
      <Card className="card--none" as="a" href={link} target="_blank" title={title}>
        {Images.exists(image) && (
          <Card.Image
            {...Images.srcSet.maxWidth(image, 500, { type: 'auto' })}
            alt={title}
            loading="lazy"
            {...imageProps}
          />
        )}
        <Card.Content style={{ textAlign }}>
          <Media title={title} subtitle={subtitle} textStyle={{ textAlign }} />
          {description && <div className="content">{description}</div>}
        </Card.Content>
      </Card>
    );
  }
}

ImageLink.defaultProps = {
  textAlign: 'center',
  imageField: undefined,
  imageProps: undefined,
  titleTemplate: '',
  subtitleTemplate: '',
  descriptionTemplate: '',
  linkField: '',
};

ImageLink.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  item: PropTypes.object.isRequired,
  imageField: PropTypes.string,
  imageProps: bulmaImageProps,
  titleTemplate: PropTypes.string,
  subtitleTemplate: PropTypes.string,
  descriptionTemplate: PropTypes.string,
  linkField: PropTypes.string,
};
