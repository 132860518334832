/* eslint-disable no-cond-assign */
import memoize from 'lodash/memoize';

/**
 * @preserve $.parseColor
 * Copyright 2011 THEtheChad Elliott
 * Released under the MIT and GPL licenses.
 */

// Parse hex/rgb{a} color syntax.
// @input string
// @returns array [r,g,b{,o}]
export function parseColor(rawColor) {
  if (!rawColor) return [];

  let cache;
  const p = parseInt; // Use p as a byte saving reference to parseInt
  const color = rawColor.replace(/\s/g, ''); // Remove all spaces

  // Checks for 6 digit hex and converts string to integer
  if ((cache = /#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/.exec(color)))
    cache = [p(cache[1], 16), p(cache[2], 16), p(cache[3], 16)];
  // Checks for 3 digit hex and converts string to integer
  else if ((cache = /#([\da-fA-F])([\da-fA-F])([\da-fA-F])/.exec(color)))
    cache = [p(cache[1], 16) * 17, p(cache[2], 16) * 17, p(cache[3], 16) * 17];
  // Checks for rgba and converts string to
  // integer/float using unary + operator to save bytes
  else if ((cache = /rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/.exec(color)))
    cache = [+cache[1], +cache[2], +cache[3], +cache[4]];
  // Checks for rgb and converts string to
  // integer/float using unary + operator to save bytes
  else if ((cache = /rgb\(([\d]+),([\d]+),([\d]+)\)/.exec(color)))
    cache = [+cache[1], +cache[2], +cache[3]];
  // Otherwise throw an exception to make debugging easier
  else {
    console.warn('Failed to parse color', color);
    return [0, 0, 0, 1];
  }

  // Performs RGBA conversion by default
  if (isNaN(cache[3])) {
    cache[3] = 1;
  }

  // Adds or removes 4th value based on rgba support
  // Support is flipped twice to prevent erros if
  // it's not defined
  return cache.slice(0, 4);
}

export const memoizedParseColor = memoize(parseColor);

/**
 *
 * @param {*} color String color (hex, rgb, rgba)
 * returns luma (from 0 to 255)
 */
export function extractColorLuma(color) {
  if (!color) return 0;
  const [r, g, b] = memoizedParseColor(color);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}
