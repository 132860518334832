/* eslint-disable operator-linebreak */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withDesign, withAppContext } from '../Context';
import { withComponents } from './Context';
import { DisabledRouterLink } from './NavLinkWithParams';
import { appDataProps } from '../utils/propTypes';
import { replaceValues } from '../utils';

function isValidUrl(url) {
  if (!url || url === 'print()') return false;
  return true;
}

class LinkButton extends PureComponent {
  handleClick = () => {
    const { url } = this.props;
    if (url === 'print()') {
      // eslint-disable-next-line no-restricted-globals
      window.print();
    }
  };

  render() {
    const { buttonColor, components, design, isEditing, text, url, data } = this.props;
    const { RichText } = components;
    const color = buttonColor === '@primaryColor' ? design.primaryColor : buttonColor;
    const isExternalLink = !isEditing && url && url.indexOf('http') === 0;
    const isValid = isValidUrl(url);
    const buttonUrl = replaceValues(url);
    const isEditinOrNotValid = isEditing || !isValid;
    const ExternalButton = isEditinOrNotValid ? 'span' : 'a';
    const Button = isEditinOrNotValid ? 'span' : Link;
    if (isExternalLink) {
      return (
        <ExternalButton
          href={buttonUrl}
          className="button is-large"
          target="_blank"
          style={{ backgroundColor: color, color: 'white' }}
        >
          <RichText html={text} name="buttonText" placeholder="Button text" configType="simple" />
        </ExternalButton>
      );
    }

    const { disableRouter } = data;
    return (
      <Button
        to={
          // SSR TODO: fix location.search detection here...
          isEditinOrNotValid
            ? undefined
            : // eslint-disable-next-line no-restricted-globals
              buttonUrl + (typeof window !== 'undefined' ? window.location.search : '')
        }
        onClick={isValid ? undefined : this.handleClick}
        component={disableRouter ? DisabledRouterLink : undefined}
        className="button is-large"
        style={{ backgroundColor: color, color: 'white' }}
      >
        <RichText html={text} name="buttonText" placeholder="Button text" configType="simple" />
      </Button>
    );
  }
}

LinkButton.defaultProps = {
  buttonColor: '@primaryColor',
  isEditing: false,
  text: undefined,
};

LinkButton.propTypes = {
  buttonColor: PropTypes.string,
  components: PropTypes.object.isRequired,
  data: appDataProps.isRequired,
  design: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default withAppContext(withComponents(withDesign(LinkButton)));
