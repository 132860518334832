import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Media } from '../bulma';
import { alpha } from '../styles/generateStyles';
import { withDesign } from '../Context';

class WorkshopDetailsModal extends PureComponent {
  render() {
    const { isActive, title, description, onClose, design, workshop } = this.props;
    const { primaryColor } = design;
    const { speakers = [] } = workshop;
    return (
      <Modal
        isActive={isActive}
        title={title}
        onClose={onClose}
        styles={{
          cardStyle: { width: '60%' },
          headStyle: { backgroundColor: primaryColor, color: 'white' },
          titleStyle: { fontSize: '1.1em', fontWeight: 'bold', color: 'white' },
        }}
        confirmBtn={{
          icon: 'edit',
          label: "S'inscrire",
          style: {
            backgroundColor: alpha(primaryColor, 0.1),
            color: primaryColor,
            border: `1px solid ${alpha(primaryColor, 0.5)}`,
          },
        }}
      >
        <div>{description}</div>
        <div style={{ marginTop: 10 }}>
          {speakers.map((speaker) => {
            const { _id, firstName, lastName, role, company, thumbnail } = speaker;
            return (
              <Media
                key={_id}
                imageLeft={thumbnail}
                imageProps={{ format: 'is-48x48', borderRadius: 'rounded' }}
                title={`${firstName} ${lastName}`}
                subtitle={`${role || ''} \n${company || ''}`}
              />
            );
          })}
        </div>
      </Modal>
    );
  }
}

WorkshopDetailsModal.defaultProps = {
  isActive: undefined,
  description: '',
  title: '',
};

WorkshopDetailsModal.propTypes = {
  design: PropTypes.object.isRequired,
  description: PropTypes.string,
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  workshop: PropTypes.object.isRequired,
};

export default withDesign(WorkshopDetailsModal);
