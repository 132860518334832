import PropTypes from 'prop-types';
import React from 'react';

import { iconProps } from '../utils/propTypes';

const prefix = {
  solid: 'fas',
  brands: 'fab',
  regular: 'far',
};

export default class FAIcon extends React.Component {
  render() {
    // Don't inject `isEditing` in DOM
    const { icon, className = '', isEditing, ...props } = this.props;
    if (!icon) return <i className={`${className} fa fa-fw fa-question`} {...props} />;
    if (typeof icon === 'string') {
      return <i className={`${className} fa fa-fw fa-${icon}`} {...props} />;
    }
    return <i className={`${className} ${prefix[icon.type]} fa-fw fa-${icon.name}`} {...props} />;
  }
}

FAIcon.defaultProps = {
  className: '',
  icon: undefined,
};

FAIcon.propTypes = {
  className: PropTypes.string,
  icon: iconProps,
};
