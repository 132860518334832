import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import ios from '../assets/apple-ios.png';
import android from '../assets/android2.png';
import iphone from '../assets/iphone.png';
import { Columns, Column } from '../components';
import { withEdit } from '../Context';

const MobileAppsButtons = ({ appstore, googleplay, className }) => (
  <div className={cx('mobile-apps-buttons', className)}>
    <a href={appstore} target="_blank" rel="noreferrer">
      <img alt="ios" src={ios} />
    </a>
    <a href={googleplay} target="_blank" rel="noreferrer">
      <img alt="android" src={android} />
    </a>
  </div>
);

MobileAppsButtons.defaultProps = {
  className: undefined,
  appstore: undefined,
  googleplay: undefined,
};

MobileAppsButtons.propTypes = {
  className: PropTypes.string,
  appstore: PropTypes.string,
  googleplay: PropTypes.string,
};

class MobileAppsSection extends PureComponent {
  renderDescription = () => {
    const { appstore, googleplay, description, components } = this.props;
    const { RichText } = components;
    return (
      <Column
        style={{ padding: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        className="force-flex-grow"
      >
        <div style={{ fontSize: '1em', marginTop: 5 }}>
          <RichText html={description} name="description" placeholder="Add Description" />
        </div>
        <MobileAppsButtons appstore={appstore} googleplay={googleplay} />
      </Column>
    );
  };

  renderPhone = () => {
    const { image, components, isEditing } = this.props;
    const imagePosition = { position: 'absolute', width: '87%', top: '12%', left: '7%' };
    const containerStyle = isEditing ? imagePosition : {};
    const imageStyle = isEditing ? { position: 'relative' } : imagePosition;
    const { Image } = components;
    return (
      <Column style={{ display: 'flex', justifyContent: 'center' }} className="is-one-third">
        <div style={{ position: 'relative' }}>
          <img src={iphone} alt="iphone" />
          <Image
            containerStyle={containerStyle}
            isUnsplash={false}
            style={{ maxHeight: 455, width: 255, ...imageStyle, borderRadius: 4 }}
            src={image}
          />
        </div>
      </Column>
    );
  };

  renderTop = () => {
    const { appstore, googleplay, description, components } = this.props;
    const { RichText } = components;
    return (
      <div style={{ display: 'block' }}>
        <div style={{ fontSize: '1em', marginTop: 5, textAlign: 'center' }}>
          <RichText html={description} name="description" placeholder="Add Description" />
        </div>
        <MobileAppsButtons className="top" appstore={appstore} googleplay={googleplay} />
      </div>
    );
  };

  render() {
    const { position, ...props } = this.props;
    return (
      <BaseSection {...props} block={this.props}>
        <div className="container">
          <Columns>
            {position === 'left' && (
              <React.Fragment>
                {this.renderDescription()}
                {this.renderPhone()}
              </React.Fragment>
            )}
            {position === 'right' && (
              <React.Fragment>
                {this.renderPhone()}
                {this.renderDescription()}
              </React.Fragment>
            )}
            {position === 'top' && <React.Fragment>{this.renderTop()}</React.Fragment>}
          </Columns>
        </div>
      </BaseSection>
    );
  }
}
export default withEdit(withComponents(MobileAppsSection));

MobileAppsSection.defaultProps = {
  appstore: '',
  googleplay: '',
  image: 'https://i.redd.it/7blsbotrkxoz.jpg',
  isEditing: false,
  position: 'left',
};

MobileAppsSection.propTypes = {
  appstore: PropTypes.string,
  components: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  googleplay: PropTypes.string,
  image: PropTypes.string,
  isEditing: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right', 'top']),
};
