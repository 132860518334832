/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './workshops-section.scss';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';
import cx from 'classnames';
import orderBy from 'lodash/orderBy';
import BaseSection from './BaseSection';
import * as templates from '../workshops/templates';
import { withAppContext } from '../Context';
import MenuFilter from '../components/MenuFilter';
import { applyFilters, generateFilters } from '../components/MenuFilter/utils';

// function parseDateTime(stringDate) {
//   return new Date(stringDate).getTime();
// }

// function filterByRange(a, b) {
//   return (
//     parseDateTime(b.startDate) > parseDateTime(a.startDate) &&
//     parseDateTime(b.endDate) < parseDateTime(a.endDate)
//   );
// }

// function applyFilter(workshop, filter) {
//   const { key, type, value } = filter;
//   if (!value) return true;
//   const dataValue = get(workshop, key);
//   if (type === 'date') {
//     // ! TODO: gérer ça autrement ???
//     const [startDate, endDate] = value.split('|');
//     const filterDate = { startDate, endDate };
//     return filterByRange(filterDate, workshop);
//   }
//   return dataValue === value;
// }

function orderWorkshop(workshops) {
  return orderBy(workshops, 'startDate', 'asc');
}

class WorkshopsSection extends PureComponent {
  state = { filterValue: {} };

  getWorkshops = () => {
    const { data, workshopIdList } = this.props;
    const { workshops = [] } = data.data || {};

    if (workshopIdList && workshopIdList.length > 0) {
      // Custom order
      const workshopsById = keyBy(workshops, '_id');
      return workshopIdList.map((id) => workshopsById[id]).filter((v) => v);
    }
    const orderedWorkshops = orderWorkshop(workshops);
    return orderedWorkshops;
  };

  setFilters = (filterValue) => this.setState({ filterValue });

  renderFilters(workshops) {
    const { filterValue } = this.state;
    const { filters, filtersConfig } = this.props;
    const {
      align = 'top',
      showFilters = true,
      // chooseFiltersLabel = 'Choisissez vos filtres',
      // clearFiltersLabel = 'Effacer tous les filtres',
    } = filtersConfig;
    const isLeft = align === 'left';
    // const hasFilters = Object.keys(filterValue).length > 0;
    if (!showFilters) return null;

    return (
      <div className={cx('workshop--filters box', { 'vertical menu': isLeft })}>
        <MenuFilter
          filterList={generateFilters(workshops, filters)}
          filters={filterValue}
          onChange={this.setFilters}
        />
      </div>
    );

    // return (
    //   <div className={cx('workshop--filters box', { 'vertical menu': isLeft })}>
    //     <div
    //       className={cx('label', { clear: hasFilters })}
    //       onClick={() => (hasFilters ? this.setState({ filterValue: {} }) : undefined)}
    //     >
    //       <i className={`fas fa-${!hasFilters ? 'filter' : 'eraser'}`} style={{ marginRight: 5 }} />
    //       {hasFilters ? clearFiltersLabel : chooseFiltersLabel}
    //     </div>
    //     {filters.map((filter) => {
    //       const { key: filterKey, options = [], title, icon } = filter;
    //       const optionsByValue = keyBy(options, 'value');
    //       const value = get(filterValue, filterKey);
    //       return (
    //         <div key={filterKey}>
    //           {isLeft ? (
    //             <div style={{ margin: '10px 0px' }}>
    //               <p className="menu-label" style={{ marginBottom: '0.5em' }}>
    //                 {title}
    //               </p>
    //               <ul className="menu-list">
    //                 {options.map(({ label, value: optionValue }) => (
    //                   <li key={optionValue}>
    //                     <label className="radio" htmlFor={filterKey}>
    //                       <input
    //                         type="radio"
    //                         name={filterKey}
    //                         value={optionValue}
    //                         checked={value === optionValue}
    //                         style={{ margin: 5 }}
    //                         onChange={() => this.selectFilter(filterKey, optionValue)}
    //                       />
    //                       {label}
    //                     </label>
    //                   </li>
    //                 ))}
    //               </ul>
    //             </div>
    //           ) : (
    //             <Dropdown
    //               leftIcon={icon}
    //               key={filterKey}
    //               title={value ? get(optionsByValue[value], 'label') : title}
    //               style={{ marginRight: 10 }}
    //               hoverable
    //               items={options}
    //               value={value}
    //               onSelect={({ value: fValue }) => this.selectFilter(filterKey, fValue)}
    //             />
    //           )}
    //         </div>
    //       );
    //     })}
    //   </div>
    // );
  }

  renderWorkshops = (workshops) => {
    const { workshopConfig, template, gridSize } = this.props;
    const Workshops = templates[template] || templates.List;
    return <Workshops workshops={workshops} workshopConfig={workshopConfig} gridSize={gridSize} />;
  };

  renderContent(workshops) {
    const { groupField } = this.props;

    if (groupField) {
      const groupedWorkshops = groupBy(workshops, groupField);
      return (
        <div style={{ flex: 1 }}>
          <ul className="timeline" style={{ width: '100%' }}>
            {Object.keys(groupedWorkshops).map((group) => {
              const groupWorkshops = groupedWorkshops[group];
              return (
                <li key={group} className="timeline-event">
                  <label className="timeline-event-icon" />
                  <div className="timeline-event-content">
                    <p className="title is-5 timeline-event-header">{group}</p>
                    {this.renderWorkshops(groupWorkshops)}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return <div style={{ flex: 1 }}>{this.renderWorkshops(workshops)}</div>;
  }

  render() {
    const { filters, filtersConfig, template } = this.props;
    const { filterValue } = this.state;
    const { align } = filtersConfig;
    const isLeft = align === 'left';
    const workshops = this.getWorkshops();
    const allFilters = pickBy(filterValue, (v) => !!v);
    const filteredData = applyFilters(workshops, allFilters, filters || []);

    return (
      <BaseSection {...this.props} block={this.props}>
        <div className={cx('workshops', template, { 'is-left': isLeft })}>
          {this.renderFilters(workshops)}
          {this.renderContent(filteredData)}
        </div>
      </BaseSection>
    );
  }
}

WorkshopsSection.defaultProps = {
  filters: [],
  filtersConfig: { align: 'top', showFilters: true },
  gridSize: 3,
  groupField: undefined,
  template: 'List',
  workshopConfig: {},
  workshopIdList: undefined,
};

WorkshopsSection.propTypes = {
  data: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
      ),
    }),
  ),
  filtersConfig: PropTypes.shape({ align: PropTypes.oneOf(['left', 'top']) }),
  gridSize: PropTypes.number,
  groupField: PropTypes.string,
  template: PropTypes.oneOf(['List', 'Masonry', 'Collapsible', 'Modern']),
  workshopConfig: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
    showDetails: PropTypes.bool,
    showSpeakers: PropTypes.bool,
    showUserCount: PropTypes.bool,
    speakersTemplate: PropTypes.string,
  }),
  workshopIdList: PropTypes.arrayOf(PropTypes.string),
};

export default withAppContext(WorkshopsSection);
