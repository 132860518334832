import React from 'react';

export const ConfigContext = React.createContext({});
export function withConfigs(Component) {
  return function ConfigComponent(props) {
    return (
      <ConfigContext.Consumer>
        {(configs) => <Component {...props} configs={configs} />}
      </ConfigContext.Consumer>
    );
  };
}

export const EditContext = React.createContext({
  onChange: (key, value) => console.log('onChange', key, value),
});
export function withEdit(Component) {
  return function EditComponent(props) {
    return (
      <EditContext.Consumer>
        {(editContext) => <Component {...props} {...editContext} />}
      </EditContext.Consumer>
    );
  };
}

export const DesignContext = React.createContext({});
export function withDesign(Component) {
  return function DesignComponent(props) {
    return (
      <DesignContext.Consumer>
        {(design) => <Component {...props} design={design} />}
      </DesignContext.Consumer>
    );
  };
}

export function providePrefix(prefix, element, key) {
  return (
    <EditContext.Consumer key={key}>
      {({ namePrefix, language, languages, onChange }) => (
        <EditContext.Provider
          value={{
            onChange,
            language,
            languages,
            namePrefix: namePrefix ? `${namePrefix}.${prefix}` : prefix,
          }}
        >
          {element}
        </EditContext.Provider>
      )}
    </EditContext.Consumer>
  );
}

export const AppContext = React.createContext({});
export function withAppContext(Component) {
  return function AppComponent(props) {
    return (
      <AppContext.Consumer>{(data) => <Component {...props} data={data} />}</AppContext.Consumer>
    );
  };
}
