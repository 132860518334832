import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import './multi-image-header-section.scss';
import { withEdit } from '../Context';
import { LinkButton } from '../components';
import { figureImageProps } from '../utils/propTypes';

class MultiImageHeaderSection extends PureComponent {
  render() {
    const {
      components,
      buttonColor,
      leftImage,
      rightImage,
      text,
      title,
      subtitle,
      url,
      buttonText,
      isEditing,
      showRightImage,
      imageProps,
      ...props
    } = this.props;
    const { Image, RichText } = components;
    return (
      <BaseSection
        className="multi-image-header hero is-medium"
        block={this.props}
        {...props}
        hasHeader={false}
      >
        <div className="multi-image-header__left">
          <Image maxWidth={800} src={leftImage} name="leftImage" imageProps={imageProps} />
        </div>
        <div className="multi-image-header__right">
          <RichText html={text} name="text" placeholder="Add text" />
          <h1 key="title" className="title is-1">
            <RichText html={title} name="title" placeholder="Add title" />
          </h1>
          <h3 key="subtitle" className="subtitle is-3">
            <RichText html={subtitle} name="subtitle" placeholder="Add subtitle" />
          </h3>
          {url && (
            <div>
              <LinkButton
                url={url}
                text={buttonText}
                buttonColor={buttonColor}
                isEditing={isEditing}
              />
            </div>
          )}
          {showRightImage && (
            <div className="rightImage">
              <Image maxWidth={600} src={rightImage} name="rightImage" imageProps={imageProps} />
            </div>
          )}
        </div>
      </BaseSection>
    );
  }
}

MultiImageHeaderSection.defaultProps = {
  buttonColor: undefined,
  buttonText: "S'inscrire",
  imageProps: undefined,
  isEditing: false,
  leftImage: undefined,
  rightImage: undefined,
  text: undefined,
  title: undefined,
  showRightImage: true,
  subtitle: undefined,
  url: undefined,
};

MultiImageHeaderSection.propTypes = {
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  leftImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showRightImage: PropTypes.bool,
  imageProps: figureImageProps,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default withEdit(withComponents(MultiImageHeaderSection));
