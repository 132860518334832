/* eslint-disable react/prefer-stateless-function */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Columns from './Columns';
import Column from './Column';

export default class Grid extends React.Component {
  render() {
    const {
      centerVertically,
      children,
      Component,
      equalHeight = false,
      items,
      gridSize = 2,
      isCentered = true,
      isGallery = false,
      isGapless,
      isHexagonal = false,
      mobileGridSize,
      render,
      tabletGridSize,
    } = this.props;
    const columnSize = Math.ceil(12 / gridSize);
    const tabletColumnSize = tabletGridSize ? Math.ceil(12 / tabletGridSize) : undefined;
    const mobileColumnSize = mobileGridSize ? Math.ceil(12 / mobileGridSize) : undefined;
    const renderer = render || children;
    return (
      <div>
        <Columns
          isMultiLine
          isDesktop={!tabletColumnSize}
          centerVertically={centerVertically}
          isCentered={isCentered && !isGallery}
          isGapless={isGapless}
          className={isHexagonal ? `hexGrid is-${gridSize}` : ''}
        >
          {items.map((item, index) => (
            <Column
              size={columnSize}
              tabletSize={tabletColumnSize}
              mobileSize={mobileColumnSize}
              key={item._id || index}
              className={cx({
                'is-equal-height': equalHeight,
                hex: isHexagonal,
              })}
            >
              {Component ? <Component {...item} /> : renderer(item, index)}
            </Column>
          ))}
        </Columns>
      </div>
    );
  }
}

Grid.defaultProps = {
  centerVertically: false,
  children: undefined,
  Component: undefined,
  equalHeight: false,
  gridSize: 2,
  isCentered: false,
  isGallery: false,
  isGapless: false,
  isHexagonal: false,
  mobileGridSize: undefined,
  render: undefined,
  tabletGridSize: undefined,
};

Grid.propTypes = {
  centerVertically: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  Component: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  equalHeight: PropTypes.bool,
  items: PropTypes.array.isRequired,
  gridSize: PropTypes.number,
  isCentered: PropTypes.bool,
  isGallery: PropTypes.bool,
  isGapless: PropTypes.bool,
  isHexagonal: PropTypes.bool,
  mobileGridSize: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  render: PropTypes.any,
  tabletGridSize: PropTypes.number,
};
