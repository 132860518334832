import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BaseSection from './BaseSection';
import { Columns, Column } from '../components';
import { withEdit } from '../Context';
import { withComponents } from '../components/Context';
import { replaceValues } from '../utils';
import './imageAndText.scss';
import { figureImageProps } from '../utils/propTypes';

class ImageAndTextSection extends PureComponent {
  getParallaxParam = () => {
    const { imageTemplate } = this.props;
    if (imageTemplate === 'parallax') {
      return 'normal';
    }
    if (imageTemplate === 'parallax-fullpage') {
      return 'fullpage';
    }
    return undefined;
  };

  renderImage(side, { isFullWidth }) {
    const { image, columnsSize, components, imageTemplate, imageFormat, imageProps } = this.props;
    const { FigureImage } = components;

    return (
      <Column className={cx(columnsSize, isFullWidth && 'column--no-vertical-padding')}>
        <FigureImage
          className={cx(`image--${side}`, imageTemplate === 'shadow' && 'image--primary-shadow')}
          format={imageFormat}
          maxWidth={700}
          loading="lazy"
          parallax={this.getParallaxParam()}
          imageProps={imageProps}
          src={image}
        />
      </Column>
    );
  }

  render() {
    const {
      imagePosition,
      text,
      components,
      registrationUrl,
      imageTemplate,
      title,
      subtitle,
      verticalAlign,
      ...props
    } = this.props;
    const { RichText } = components;

    const isFullWidth =
      imageTemplate === 'full-width' ||
      imageTemplate === 'parallax' ||
      imageTemplate === 'parallax-fullpage';

    return (
      <BaseSection
        className={cx('section', isFullWidth && 'section--no-vertical-padding')}
        {...props}
        title={title}
        subtitle={subtitle}
        isFullWidth={isFullWidth}
        block={this.props}
        hasHeader={!isFullWidth}
      >
        <Columns
          className={imagePosition === 'right' ? 'is-reversed' : ''}
          isGapless={isFullWidth}
          verticalAlign={verticalAlign}
        >
          {this.renderImage(imagePosition, { isFullWidth })}
          <Column className={cx(isFullWidth && 'column--extra-padding')}>
            {registrationUrl && (
              <iframe
                title="Registration"
                width="100%"
                height="400"
                src={replaceValues(registrationUrl)}
                style={{ overflow: 'hidden' }}
                scrolling="no"
              />
            )}
            {isFullWidth && <BaseSection.Header title={title} subtitle={subtitle} />}
            <RichText html={text} name="text" placeholder="Texte" />
          </Column>
        </Columns>
      </BaseSection>
    );
  }
}

ImageAndTextSection.defaultProps = {
  columnsSize: 'is-half',
  imageFormat: undefined,
  imagePosition: 'left',
  imageTemplate: 'none',
  registrationUrl: null,
  subtitle: undefined,
  title: undefined,
  text: undefined,
  imageProps: undefined,
  verticalAlign: 'center',
};
ImageAndTextSection.propTypes = {
  columnsSize: PropTypes.string,
  components: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  imageFormat: PropTypes.string,
  imagePosition: PropTypes.string,
  imageTemplate: PropTypes.string,
  imageProps: figureImageProps,
  registrationUrl: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
};

export default withEdit(withComponents(ImageAndTextSection));
