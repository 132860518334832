import React from 'react';
import PropTypes from 'prop-types';

const WIDTH = 1000;

class Diagonal extends React.PureComponent {
  computePoints() {
    const { ratio, flip } = this.props;
    const height = WIDTH * ratio;
    if (flip) {
      return [
        [0, height],
        [WIDTH, height],
        [WIDTH, 0],
      ];
    }
    return [
      [0, 0],
      [0, height],
      [WIDTH, height],
    ];
  }

  render() {
    const { color, ratio, flip, style, ...props } = this.props;

    const svgPoints = this.computePoints().join(' ');
    return (
      <svg
        {...props}
        viewBox={`0 0 ${WIDTH} ${ratio * WIDTH}`}
        style={{ pointerEvents: 'none', ...style }}
      >
        <polygon points={svgPoints} style={{ fill: color }} />
      </svg>
    );
  }
}

Diagonal.defaultProps = {
  ratio: 0.1,
  flip: false,
  style: undefined,
};

Diagonal.propTypes = {
  color: PropTypes.string.isRequired,
  ratio: PropTypes.number,
  flip: PropTypes.bool,
  style: PropTypes.object,
};

export default Diagonal;
