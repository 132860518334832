import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { replaceValues } from '../utils';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import ResizableIframe from '../components/ResizableIframe';

class RegistrationIFrame extends PureComponent {
  render() {
    const { source = '', hasAutoResize, ...props } = this.props;
    const url = replaceValues(source);
    return (
      <BaseSection {...props} block={this.props} hasHeader={false} containerStyle={{ padding: 0 }}>
        {!source && (
          <div style={{ padding: 32 }}>
            Paramétrez l'url du formulaire dans les options de droite
          </div>
        )}
        <ResizableIframe src={url} autoResize={hasAutoResize} />
      </BaseSection>
    );
  }
}

RegistrationIFrame.defaultProps = {
  isPreview: false,
  source: '',
  hasAutoResize: true,
};

RegistrationIFrame.propTypes = {
  hasAutoResize: PropTypes.bool,
  isPreview: PropTypes.bool,
  source: PropTypes.string,
};

export default withComponents(RegistrationIFrame);
