import React from 'react';
import cx from 'classnames';
import { Parallax } from 'react-parallax';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Images from '../utils/Images';
import { figureImageProps, loadingType } from '../utils/propTypes';
import { useHasClientSideChecks } from '../utils/hooks';

let hasObjectFit = false;
if (process.env.REACT_APP_BUILD_TARGET !== 'server') {
  // Access to "document" won't work in SSR
  // eslint-disable-next-line no-restricted-globals
  const docElemStyle = document.documentElement.style;

  // eslint-disable-next-line no-inner-declarations
  function browserHasStyleProperty(propName) {
    if (!propName) {
      return false;
    }
    return typeof docElemStyle[propName] === 'string';
  }

  hasObjectFit = browserHasStyleProperty('object-fit');
}

export function computeCDNUrl({ maxWidth, src, square, type }) {
  const url = src && (typeof src === 'string' ? src : src.uri);

  // Only use CDN/resize for appcraft event images
  if (url && url.indexOf('appcraft.events') !== -1) {
    const options = { type };
    if (maxWidth && square) return Images.square(src, maxWidth, options);
    if (maxWidth) return Images.maxWidth(src, maxWidth, options);
    return Images.cdn(src);
  }
  return url;
}

function getEditorIFrame() {
  // eslint-disable-next-line no-restricted-globals
  if (typeof window !== 'undefined' && window.editorIframe) {
    // eslint-disable-next-line no-restricted-globals
    return window.editorIframe.contentWindow.document;
  }
  return undefined;
}

const FigureImage = React.memo(
  ({ alt, src, maxWidth, square, format, className, style, parallax, loading, imageProps }) => {
    const hasClientSideChecks = useHasClientSideChecks();
    const requiresObjectFit = !!format;

    const isOriginalFormat = format === '';
    const url = computeCDNUrl({ src, maxWidth, square, type: get(imageProps, 'type', 'original') });
    const imageAlt = (src && src.alt) || alt;

    if (parallax === 'normal') {
      return (
        <Parallax
          bgImage={url}
          bgImageAlt={imageAlt}
          key={format} // Reboot component on format change
          parent={getEditorIFrame()}
          strength={200}
        >
          <div className={cx('image', className, format || 'is-16by9')} />
        </Parallax>
      );
    }
    if (parallax === 'fullpage') {
      return (
        <div
          className={cx('parallax image', className, format || 'is-16by9')}
          style={{ backgroundImage: `url("${url}")` }}
        />
      );
    }

    if (hasClientSideChecks && requiresObjectFit && !hasObjectFit) {
      // Fallback with background image
      return (
        <figure
          className={cx('image', className, format)}
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize: style.objectFit || 'cover',
            backgroundPosition: 'center center',
            position: 'relative',
          }}
        >
          <img
            alt={imageAlt}
            className="has-ratio"
            src={url}
            loading={loading}
            style={{
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
            }}
          />
        </figure>
      );
    }

    return (
      <figure className={cx('image', className, format)}>
        <img
          alt={imageAlt}
          className="has-ratio"
          src={url}
          loading={loading}
          style={{
            objectFit: requiresObjectFit ? style.objectFit || 'cover' : undefined,
            width: isOriginalFormat ? 'unset' : undefined,
          }}
        />
      </figure>
    );
  },
);

FigureImage.defaultProps = {
  alt: undefined,
  className: '',
  format: undefined,
  imageProps: {},
  loading: undefined,
  maxWidth: undefined,
  parallax: undefined,
  square: undefined,
  style: {},
  src: undefined,
};

FigureImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.string,
  loading: loadingType,
  maxWidth: PropTypes.number,
  parallax: PropTypes.string,
  square: PropTypes.string,
  style: PropTypes.object,
  imageProps: figureImageProps,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FigureImage;
