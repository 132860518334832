/* eslint-disable react/button-has-type */
import React from 'react';
import ScrollLock from 'react-scrolllock';

const Modal = (props) => {
  const {
    title,
    maxWidth = 960,
    onClose,
    footer,
    backgroundColor = '#fafafa',
    children,
    isActive = true,
    style,
  } = props;
  const active = isActive ? 'is-active' : '';
  return (
    <div
      className={`modal ${active}`}
      style={{
        zIndex: 1000,
        color: 'black',
      }}
    >
      <div className="modal-background" />
      <div className="modal-card" style={{ ...style, width: maxWidth }}>
        <header
          className="modal-card-head"
          style={{
            backgroundColor: '#fff',
          }}
        >
          <div className="modal-card-title">{title}</div>
          <button className="delete" aria-label="close" onClick={onClose} />
        </header>
        <section
          className="modal-card-body"
          style={{
            backgroundColor,
            position: 'relative',
          }}
        >
          {children}
        </section>
        {footer}
      </div>
      {/* <ScrollLock isActive={active} /> */}
    </div>
  );
};

export default Modal;
