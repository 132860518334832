import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// TODO: should be in a env config
export const KEY_API = 'AIzaSyBBQKAvuPLxgX7ERfHYMa2NRpP1WCGomiE';
export default class Map extends PureComponent {
  render() {
    const { address, height } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <iframe
          title="google_maps"
          width="100%"
          height={height}
          src={`https://www.google.com/maps/embed/v1/place?key=${KEY_API}&q=${address}`}
        />
      </div>
    );
  }
}

Map.defaultProps = {
  address: 'Champ de Mars, 5 Avenue Anatole France, 75007 Paris',
  height: 80,
};

Map.propTypes = {
  address: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
