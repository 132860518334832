import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Column extends PureComponent {
  render() {
    const { size, tabletSize, mobileSize, className = '', style, children } = this.props;
    return (
      <div
        className={cx(
          'column',
          {
            [`is-${size}`]: size,
            [`is-${size}-desktop`]: tabletSize, // Inject size as "wide" if tablet specified
            [`is-${size}-desktop`]: tabletSize, // Inject size as "desktop" if tablet specified
            [`is-${tabletSize}-tablet`]: tabletSize,
            [`is-${mobileSize}-mobile`]: mobileSize,
          },
          className,
        )}
        style={style}
      >
        {children}
      </div>
    );
  }
}

Column.defaultProps = {
  className: '',
  children: undefined,
  style: undefined,
  size: undefined,
  mobileSize: undefined,
  tabletSize: undefined,
};

Column.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  size: PropTypes.number,
  mobileSize: PropTypes.number,
  tabletSize: PropTypes.number,
};
