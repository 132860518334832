import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './countdown.scss';
import { parseISO } from 'date-fns';
import BaseSection from './BaseSection';
import Countdown from '../components/Countdown';
import CountdownCircle from '../components/CountdownCircle';

export default class CountdownSection extends PureComponent {
  state = {
    now: Date.now(),
  };

  componentDidMount() {
    this._isMounted = true;
    this.refreshTimer();
  }

  componentWillUnmount() {
    this._isMounted = true;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  refreshTimer = () => {
    this.interval = setInterval(() => {
      if (this._isMounted) {
        this.setState({ now: Date.now() });
      }
    }, 1000);
  };

  render() {
    const { template, color, background, strings, date, ...props } = this.props;
    const { now } = this.state;
    const then = parseISO(date);
    const secondsLeft = Math.floor((then.getTime() - now) / 1000);
    const seconds = secondsLeft % 60;
    const minutes = Math.floor(secondsLeft / 60) % 60;
    const hours = Math.floor(secondsLeft / 3600) % 24;
    const days = Math.floor(secondsLeft / (24 * 3600));

    let timeLeft = { days, hours, minutes, seconds };
    if (then < now) {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return (
      <BaseSection {...props} block={this.props}>
        {(template === 'square' || template === 'circle1') && (
          <Countdown
            background={background}
            color={color}
            labels={strings}
            timeLeft={timeLeft}
            type={template}
          />
        )}
        {template === 'circle2' && (
          <CountdownCircle
            background={background}
            color={color}
            labels={strings}
            timeLeft={timeLeft}
          />
        )}
      </BaseSection>
    );
  }
}

CountdownSection.defaultProps = {
  background: undefined,
  color: undefined,
  date: '2020-09-09 14:00',
  format: 'YYYY-MM-DD hh:mm',
  template: 'square',
  strings: {},
};
CountdownSection.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  date: PropTypes.string,
  format: PropTypes.string,
  template: PropTypes.oneOf(['square', 'circle1', 'circle2']),
  strings: PropTypes.object,
};
