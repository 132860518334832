/* eslint-disable global-require */
import AccessSection from './AccessSection';

import ContactSection from './ContactSection';
import DataItemSection from './DataItemSection';
import Hero from './Hero';
import HeroImage from './HeroImage';
import HeroTextSection from './HeroTextSection';
import InformationSection from './InformationSection';
import ProgramSection from './ProgramSection';
import ImageAndTextSection from './ImageAndTextSection';
import TextSection from './TextSection';
import RemoteTextSection from './RemoteTextSection';
import TextButtonSection from './TextButtonSection';
import TrombinoscopeSection from './TrombinoscopeSection';
import VideoSection from './VideoSection';

import PartnersSection from './PartnersSection';
import MobileAppsSection from './MobileAppsSection';
import Footer from './Footer';
import RegistrationIFrame from './RegistrationIFrame';
import IFrameSection from './IFrameSection';
import TabsSection from './TabsSection';
import ListSection from './ListSection';
import OpportunitiesSection from './OpportunitiesSection';
import OpportunityDetailsSection from './OpportunityDetailsSection';
import VerticalTimelineSection from './VerticalTimelineSection';
import WorkshopsSection from './WorkshopsSection';
import WorkshopDetailsSection from './WorkshopDetailsSection';
import CountdownSection from './CountdownSection';
import TextImageShapeSection from './TextImageShapeSection';
import MultiImageHeaderSection from './MultiImageHeaderSection';

const sections = {
  AccessSection,
  ContactSection,
  DataItemSection,
  Hero,
  HeroImage,
  HeroTextSection,
  InformationSection,
  ProgramSection,
  ImageAndTextSection,
  TextSection,
  RemoteTextSection,
  TextButtonSection,
  TrombinoscopeSection,
  VideoSection,
  PartnersSection,
  MobileAppsSection,
  Footer,
  RegistrationIFrame,
  IFrameSection,
  TabsSection,
  ListSection,
  OpportunitiesSection,
  OpportunityDetailsSection,
  VerticalTimelineSection,
  WorkshopsSection,
  WorkshopDetailsSection,
  CountdownSection,
  TextImageShapeSection,
  MultiImageHeaderSection,
};

if (
  process.env.REACT_APP_BUILD_TARGET === 'server' ||
  process.env.REACT_APP_BUILD_TARGET === 'editor'
) {
  sections.MapSection = require('./MapSection').default;
  Object.assign(sections, require('./PhotosSection'));
} else {
  // export { default as MapSection } from './MapSection';
  // TODO: only load if required... (flag in window.__DATA__ ??)
  // eslint-disable-next-line no-restricted-globals
  if (process.env.NODE_ENV !== 'production' && window.__DATA__) {
    // eslint-disable-next-line no-restricted-globals
    window.__DATA__.extraModules = ['MapSection', 'PhotoSections'];
  }

  // eslint-disable-next-line no-inner-declarations
  function notifyLoadedModule(module) {
    // eslint-disable-next-line no-restricted-globals
    if (!window.__DATA__.loadedModules) {
      // eslint-disable-next-line no-restricted-globals
      window.__DATA__.loadedModules = {};
    }
    // eslint-disable-next-line no-restricted-globals
    const { loadedModules } = window.__DATA__;
    loadedModules[module] = true;
  }

  // eslint-disable-next-line no-restricted-globals
  const { extraModules = [] } = window.__DATA__;
  // Only load required modules
  if (extraModules.indexOf('MapSection') !== -1) {
    import(
      /* webpackChunkName: "MapSection" */
      /* webpackInclude: /./ */
      './MapSection'
    ).then((MapSection) => {
      sections.MapSection = MapSection.default;
      notifyLoadedModule('MapSection');
    });
  }

  if (extraModules.indexOf('PhotoSections') !== -1) {
    import(
      /* webpackChunkName: "PhotoSections" */
      /* webpackInclude: /./ */
      './PhotosSection'
    ).then((PhotosSection) => {
      Object.assign(sections, PhotosSection);
      notifyLoadedModule('PhotoSections');
    });
  }
}
// export { default as CarouselSection } from './CarouselSection';
// export { default as GallerySection } from './GallerySection';
// export { default as AlbumSection } from './AlbumSection';

export default sections;
