import get from 'lodash/get';
import { format, parseISO } from 'date-fns';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';

const locales = { en, fr };
// TODO: adapt locale to website language...

// ! TODO: PUT inside assets folder ?
export const languages = {
  fr: 'https://app.appcraft.events/images/Rvwp7c0UvXZYhU/assets/jSxKBA45x+nuU-dLcZgt7FWL-Ko_.png',
  en: 'https://app.appcraft.events/images/Rvwp7c0UvXZYhU/assets/4Ef6oFtstxWqdyn03HLTPIbOXUQ_.png',
};

const dateFormats = {
  fr: 'EEEE, d LLLL yyyy',
  // en: 'dddd, MMMM D YYYY',
  en: 'EEEE, LLLL do, yyyy',
};

const timeFormats = {
  fr: 'HH:mm',
  en: 'h:mm aa',
};

/**
 *
 * date-fns doesn't like YYYY-MM-DD, convert to yyyy-MM-dd
 */
// function cleanFormat(dateFormat) {
//   if (!dateFormat) return dateFormat;
//   return dateFormat.replace('Y', 'y').replace('DD', 'dd');
// }

export function formatForLang(date, dateFormat, options = {}) {
  if (!date) return '';
  const { lang = 'fr' } = options;
  const d = parseISO(date.replace(' ', 'T'));
  return format(d, dateFormat, { locale: get(locales, lang, fr) });
}

export function formatDate(date, options = {}) {
  if (!date) return '';
  const { lang = 'fr' } = options;
  const d = parseISO(date.replace(' ', 'T'));
  return format(d, get(dateFormats, lang, dateFormats.fr), { locale: get(locales, lang, fr) });
}

export function formatDatetime(date, dateFormat, options = {}) {
  if (!date) return '';
  const { lang = 'fr' } = options;
  const d = parseISO(date.replace(' ', 'T'));
  return format(d, get(timeFormats, lang, dateFormats.fr) /* cleanFormat(dateFormat) */, {
    locale: get(locales, lang, fr),
  });
}
